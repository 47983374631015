import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core'
import { Purchase } from './Purchase'
import { ListItem, ListItemText } from '@material-ui/core'
import { useMediaQuery } from 'react-responsive'
import { purchaseListStyle } from '../../assets/jss/components/purchaseHistoryList'
import Context from 'context/Context'

const useStyles = makeStyles(purchaseListStyle)

export const PurchaseHistoryList = (props) => {
  const classes = useStyles()
  const isMobile = useMediaQuery({ query: '(max-width: 640px)' })
  const { purchases, openDetail } = props

  const contextType = useContext(Context)
  const { t } = contextType

  return purchases.length > 0 ? (
    <>
      <ListItem classes={{ root: isMobile ? classes.listItemRootMobile : classes.listItemRoot }} target='_blank'>
        <ListItemText classes={{ primary: classes.textItemFormat }} primary={''} />
        <ListItemText classes={{ primary: classes.textItemFormat }} primary={t('date')} />
        <ListItemText classes={{ primary: classes.textItemFormat }} primary={t('event_name')} />
        <ListItemText classes={{ primary: classes.textItemFormat }} primary={t('state')} />
        <ListItemText classes={{ primary: classes.textItemFormat }} primary={t('ticket_detail')} />
      </ListItem>
      {purchases.map((data) => (
        <>
          <Purchase {...data} openDetail={openDetail} />
        </>
      ))}
    </>
  ) : (
    <div style={{ height: '50%', width: '100%', textAlign: 'center', alignItems: 'center' }}>
      <p>{t('not_purchases').toUpperCase()}</p>
    </div>
  )
}
