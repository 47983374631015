import React, { useContext, useState, useEffect } from "react";
import {
  Button,
  Grid,
  TextField,
  Divider,
  Switch,
  createMuiTheme,
  MenuItem,
  CircularProgress,
  Container,
} from "@material-ui/core";
import GroupIcon from "@material-ui/icons/Group";
import HomeIcon from "@material-ui/icons/Home";
import { makeStyles } from "@material-ui/core/styles";
import Context from "../../../context/Context";
import orange from "@material-ui/core/colors/orange";
import { ThemeProvider } from "@material-ui/styles";

import styles from "../../../assets/jss/views/frequentAddressPage";
import { useFormik, Field, FormikProvider, ErrorMessage } from "formik";

import { getCounties } from "provider/publicProvider/provider.js";
import {
  getFrequentAddresses,
  postFrequentAddresses,
  putFrequentAddresses,
} from "../../../provider/privateProvider/provider";
import { frequentAddressesSchema } from "../../../utils/validations";

const useStyles = makeStyles(styles);

const FrequentAddresses = (props) => {
  const contextType = useContext(Context);
  const { t } = contextType;
  const [counties, setCounties] = useState([]);
  const [loader, setLoader] = useState(true);

  const classes = useStyles();
  const [data, setData] = useState([]);
  const [myAddressParse, setMyAddressParse] = useState(0);

  useEffect(() => {
    getFrequentAddressesApi();
  }, []);

  useEffect(() => {
    getCounties()
      .then((response) => {
        setCounties(response);
      })
      .finally(() => {
        setLoader(false);
      });
  }, []);

  const getFrequentAddressesApi = () => {
    getFrequentAddresses()
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        console.error("error_frequent_addresses_get", error);
      });
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      countyId: "",
      myAddress: false,
    },
    validationSchema: frequentAddressesSchema,
    onSubmit: (values) => {
      postFrequentAddresses({
        name: values.name,
        countyId: values.countyId,
        myAddress: values.myAddress ? 1 : 0,
      }).then(() => {
        getFrequentAddressesApi();
      });
    },
  });

  const defaultMaterialTheme = createMuiTheme({
    palette: {
      primary: {
        main: orange["700"],
        dark: orange["800"],
        contrastText: "#FFFFFF",
      },
    },
  });

  if (loader) {
    return (
      <Container>
        <ThemeProvider theme={defaultMaterialTheme}>
          <Grid
            item
            xs={12}
            md={12}
            classes={classes.boxCircularProgress}
            style={{
              height: "500px",
              alignItems: "center",
              paddingTop: "40%",
              paddingLeft: "50%",
            }}
          >
            <CircularProgress />
          </Grid>
        </ThemeProvider>
      </Container>
    );
  }

  return (
    <div className={classes.root}>
      <Grid container className={classes.contentWrapper} spacing={2}>
        <Grid item md={12} xs={12} className={classes.tar}>
          <span className={classes.title}>{t("frequent_addresses")}</span>
        </Grid>
        <Grid container xs={12} md={12} className={classes.addressListBox}>
          {data.length > 0 ? (
            data.map(({ id, name, county, myAddress }) => (
              <>
                <Grid item xs={2} md={1}>
                  {myAddress ? (
                    <HomeIcon className={classes.customIcon} />
                  ) : (
                    <GroupIcon className={classes.customIcon} />
                  )}
                </Grid>
                <Grid item xs={10} md={5} className={classes.addressName}>
                  {name}
                </Grid>
                <Grid item xs={12} md={3}>
                  {county.name}
                </Grid>
                <Grid item xs={12} md={3} style={{ textAlign: "center" }}>
                  <Button
                    className={
                      myAddress
                        ? classes.selectedAddressButton
                        : classes.selectAddressButton
                    }
                    onClick={() =>
                      putFrequentAddresses(id).then(() =>
                        getFrequentAddressesApi()
                      )
                    }
                  >
                    {t("my_address")}
                  </Button>
                </Grid>
                <Grid item xs={12} md={12} className={classes.divider}>
                  <Divider variant="middle" />
                </Grid>
              </>
            ))
          ) : (
            <>
              <Grid
                item
                xs={12}
                md={12}
                className={classes.unregisteredAddresses}
              >
                <span>{t("you_dont_have_frequent_addresses")}</span>
              </Grid>
              <Grid item xs={12} md={12}>
                <Divider variant="middle" />
              </Grid>
            </>
          )}
        </Grid>
        <Grid item xs={12} md={12}>
          <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={2} className={classes.addressFormBox}>
                <Grid item md={6} xs={12}>
                  <Grid item md={12} xs={12}>
                    {t("street_number_dept")}
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Field
                      as={TextField}
                      name="name"
                      placeholder="María ..."
                      onChange={formik.handleChange}
                      value={formik.values.name}
                      variant="outlined"
                      fullWidth
                      className={classes.field}
                      helperText={<ErrorMessage name="name" />}
                    />
                  </Grid>
                </Grid>
                <Grid item md={3} xs={12}>
                  <Grid item md={12} xs={12}>
                    {process.env.REACT_APP_TRANSLATE_DISTRICT}
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Field
                      as={TextField}
                      name={"countyId"}
                      placeholder={process.env.REACT_APP_TRANSLATE_DISTRICT}
                      onChange={formik.handleChange}
                      value={formik.values.countyId}
                      variant="outlined"
                      fullWidth
                      select
                      className={classes.field}
                    >
                      {counties.map(({ id, name }) => (
                        <MenuItem key={id} value={id}>
                          {name}
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid>
                </Grid>
                <Grid item md={3} xs={12}>
                  <Grid item md={12} xs={12}>
                    {t("assign_to_my_address")}
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Switch
                      checked={formik.myAddress}
                      onChange={formik.handleChange}
                      color="primary"
                      name="myAddress"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                md={12}
                xs={12}
                className={classes.addAddressButtonGrid}
              >
                <Button className={classes.addAddressButton} type="submit">
                  {t("create_address")}
                </Button>
              </Grid>
            </form>
          </FormikProvider>
        </Grid>
      </Grid>
    </div>
  );
};

export default FrequentAddresses;
