import React from 'react'
import styles from 'assets/jss/views/eventPageSections/paymentTabStyle.js'
import PaymentPlus from 'assets/img/vmove/payment/webpay-plus.png'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(styles)

const UnlimitCodiButton = (props) => {
    const { paymentMethod, handlePaymentMethod } = props
    const classes = useStyles()

    return (
        <Button
            onClick={handlePaymentMethod('unlimit_codi')}
            className={paymentMethod === 'unlimit_codi' ? classes.buttonPaymentActive : classes.buttonPayment}
        >
            {/* <img alt='PaymentPlus' src={PaymentPlus} className={classes.imagePayment} /> */}
            CoDi
        </Button>
    )
}

export default UnlimitCodiButton
