const language = {
    about_us: "Quiénes Somos",
    about_us_left_1:
        "Creamos soluciones tecnológicas de traslados compartidos con presencia en Mexico, Chile, España, Perú y Colombia.",
    about_us_left_2:
        "Nuestro compromiso no sólo es entregar el mejor servicio para nuestros usuarios sino también ser un aporte para el mundo actual, ayudando a reducir la descongestión vehicular y ayuda a disminuir las emisiones de CO2 que son nocivas para el medio ambiente.",
    about_us_right_1:
        "Somos la primera ticketera de traslados entre tu evento y tu casa. Contamos con una plataforma web, donde encontrarás Eventos Privados, Eventos Masivos y traslados personalizados, ofreciéndote la mejor experiencia en cada viaje. Cuidamos cada detalle y pensamos en ti como nuestra prioridad, para que te sientas a gusto y seguro antes de tu evento y también después, una vez que hayas llegado a casa.",
    vmove_companies_right_1:
        "Somos una plataforma profesional de traslados corporativos, que une lo mejor del servicio de atención personal con nuestros softwares y APP. Ayudando a optimizar rutas, generando ahorros de hasta un 35%, disminuyendo así la congestión en nuestras ciudades y emisiones del CO2. Siendo así la red de VANs más grande de Chile, con presencia en España y Perú.",
    account_id: "Número de cuenta",
    account_type: "Tipo de cuenta",
    active_events: "Activos",
    add_more: "Agregar Más",
    add_stop: "Agregar Parada",
    add_frequent_friend: "Agregar amigo frecuente",
    add_friend: "Agregar Amigo",
    add: "Agregar",
    address_input: "Dirección",
    address_is_required: "Dirección es requerida",
    address_single: "Dirección",
    address: "Paseo de la Reforma 296, Juarez, Cuauhtemoc, 06600, CDMX",
    addresses: "Direcciones",
    all_reservations_include:
        "Todas las reservas incluyen ticket de traslado de ida y vuelta",
    alliances: "Eventos BB",
    already_a_user: "Ya eres usuario",
    assign_to_my_address: "Asignar como Mi Dirección",
    available_locations: "Locales disponibles",
    awaiting_payment: "Pendiente",
    awaiting_transfer_payment: "Pendiente",
    back_to_main_screen: "Volver a pantalla principal",
    bank: "Banco",
    benefits: "Beneficios",
    birthday: "Cumpleaños",
    book_our_services:
        "ÚNETE A NUESTRA COMUNIDAD, ALGUNOS DE LOS CLIENTES QUE HOY TRASLADAMOS",
    boyfriends: "Novios",
    bride_and_groom_name: "Nombres de los novios",
    build_your_route: "Arma tu Ruta",
    buy_your_transfer: "Compra tu traslado desde",
    by: "de",
    cancelled: "Cancelado",
    category: "Categoría",
    change_city: "Cambiar Ciudad",
    change_date: "Cambiar Fecha",
    choose_the_activity: "Escoje la actividad",
    clicking_register_termins: "Al hacer click en registrarse, aceptas los",
    commune: "Colonia",
    confirm_your_purchase: "Confirma tu compra",
    congratulations: "¡Felicitaciones!",
    contact: "Contacto",
    continue: "Continuar",
    copy_right: "Hecho por vmove. Todos los derechos reservados",
    corporate_events: "Eventos Corporativos",
    countie_is_required: "Colonia es requerida",
    create_address: "Crear Dirección",
    create_event: "Crear Evento",
    create_event_success: "Solicitud creada Exitosamente",
    create_event_failed: "Error en la creación de la solicitud",
    create_private_event_title: "Crea tu evento privado",
    create_private_event_name: "Nombre del evento",
    create_private_event_name_form: "Cumpleaños de María",
    create_private_event_date: "Fecha y hora de inicio y término",
    create_private_event_date_start: "Fecha de inicio",
    create_private_event_date_start_hour: "Hora de inicio",
    create_private_event_date_end_hour: "Hora de término",
    create_private_event_place: "Localización del Evento",
    create_private_event_place_name: "Nombre del recinto del evento",
    create_private_event_place_name_form: "Jardín de...",
    create_private_event_address: "Dirección del recinto",
    create_private_event_add_stop_parade: "Agregar parada",
    create_private_event_add_stop_parade_address: "Dirección",
    create_private_event_add_stop_parade_county: "Colonia",
    create_private_event_add_stop_parade_hour: "Hora",
    create_private_event_image: "Imagen principal del evento",
    create_private_event_image_search: "Buscar Archivo",
    create_private_event_image_no_selected: "Ningún archivo seleccionado",
    create_private_event_invitation: "Texto de invitación al evento",
    create_private_event_invitation_text: "Escribe un mensaje de invitación",
    create_private_event_invitation_text_form: "Los invito a...",
    create_private_event_guest_list: "Lista de invitados",
    create_private_event_guest_list_description:
        "Debes subir tu lista en el archivo xls disponible para descargar. Puedes hacerlo ahora o después.",
    create_private_event_guest_list_format_file: "Archivo xlsx",
    create_private_event_guest_list_download_file: "Descargar Archivo",
    create_private_event_guest_list_upload_file: "Subir Archivo",
    create_private_event_send_form: "Enviar",
    create_private_event_save_form: "Guardar Información",
    create_private_event_cancel: "Cancelar",
    create_private_event_guest_list_error: "Debe subir una lista de invitados.",
    create_private_event_image_error: "Debe subir una imagen del evento",
    current_events: "Eventos Actuales",
    date: "Fecha",
    detail_of_your_reservation: "Detalle de tu reserva",
    details: "Detalles",
    dni: "DNI",
    edit_addresses: "Editar Dirección",
    edit_categories: "Editar Categorias",
    edit_passengers: "Editar Pasajeros",
    edit_tickets: "Editar Tickets",
    edit: "Editar",
    email_is_invalid: "Correo electrónico es invalido",
    email_is_required: "Correo electrónico es requerido",
    email: "Correo electrónico",
    enter_your_address: "Ingresa tu dirección",
    error_payment_body:
        "No se ha podido procesar el pago. Por favor inténtalo nuevamente",
    error_payment_subitle: "Se produjo un error de pago",
    event_approved: "Aprobado",
    event_invited: "Invitado",
    event_under_review: "En revisión",
    event_saved: "Guardado",
    event_finished: "Finalizado",
    event_repeat: "Repetir",
    event_history: "Historial de Eventos",
    event_name: "Nombre Evento",
    event_edit: "Editar evento",
    events: "Eventos",
    famous_routes: "Rutas Famosos",
    finish: "Finalizar",
    finished_events: "Finalizados",
    forget_password: "Olvidaste tu contraseña",
    frequent_questions: "Preguntas Frecuentes",
    frequent_friends: "Amigos Frecuentes",
    frequent_addresses: "Direcciones Frecuentes",
    friends: "Amigos",
    from: "Desde",
    gender: "Género",
    here: "aquí",
    home: "Inicio",
    hours: "Horas",
    how_to_book: "Cómo Reservar",
    incorrectly: "Rechazado",
    in_love: "Enamorados",
    incomplete_form:
        "El formulario se encuentra incompleto. Verifique los datos de los tickets para continuar.",
    incomplete_tickets: "Se debe agregar al menos 1 ticket para continuar.",
    information_to_add_addresses:
        "Puedes agregar desde 1 dirección hasta un máximo de",
    information_to_add_passenger:
        "Puedes agregar desde 1 pasajero hasta un máximo de",
    invited_events: "Invitado",
    join_our_community: "Únete a nuestra comunidad",
    lastname_is_required: "El apellido es requerido",
    lastname_must_contain_only_letters: "El apellido debe contener solo letras",
    log_in: "Inicia Sesión",
    login_error:
        "El correo electrónico o la contraseña que has ingresado son incorrectos.",
    login_success: "Inicio de sesión exitoso.",
    marriages: "Matrimonios",
    massive_and_private_events: "Eventos Masivos y Privados",
    massive_events: "Eventos Masivos",
    maximum: "Máximo",
    men_stag_party: "Despedida de Soltero",
    menu: "Menú",
    miscellaneous: "Miscelaneos",
    name_and_surname: "Nombre y Apellido",
    name_is_required: "El nombre es requerido",
    name_must_contain_only_letters: "El nombre debe contener solo letras",
    name: "Nombre",
    names: "Nombres",
    my_address: "Mi dirección",
    need_help: "Necesitas ayuda",
    no_avariable: "No disponible para esta categoría",
    no_event_created: "Aún no has creado un evento privado",
    no_events_available: "No hay eventos disponibles para esta Categoría",
    no_tickets_available: "No hay tickets disponibles",
    not_have_account: "No tienes una cuenta en Vmove",
    not_purchases: "Este usuario no tiene compras asociadas.",
    number_of_people: "Número de personas",
    oops: "Ups!",
    our_service_includes: "Nuestro Servicio Incluye",
    our_team: "Nuestro Equipo",
    paid: "Pagado",
    passenger_list: "Lista de Pasajeros",
    passenger: "Pasajero",
    passengers_and: "pasajeros y",
    passengers: "Pasajeros",
    password_is_required: "Contraseña es requerida",
    password_must_be_characters: "La contraseña debe tener 5 o más caracteres",
    password: "Contraseña",
    pay: "Pagar",
    payment_info: "Tu pago ha sido",
    payment_problem_1: "Te enviaremos un correo de verificación",
    payment_problem_2: "con detalle de posibles fallas ocasioandas",
    payment_success_1: "Te enviaremos un correo de confirmación",
    payment_success_2: "con el codigo QR y detalle del Ticket",
    payment_state: "Estado de pago",
    phone_is_invalid: "Teléfono es inválido",
    phone_is_required: "Teléfono es requerido",
    phone_must_only_contain_numbers: "El teléfono debe contener solo números",
    phone_number: "Número de teléfono",
    phone: "Teléfono Móvil",
    place: "Lugar",
    please_select_method: "Por favor, seleccione su método de pago.",
    price: "Precio",
    private_events: "Eventos Privados",
    profile: "Perfil",
    purchase_date: "Fecha de compra",
    purchase_history: "Historial de Compra",
    purchase_order: "Orden de compra",
    recommended_transfer: "Traslado Recomendado",
    recommended_transfers: "Traslados Recomendados",
    register: "Registrarse",
    remove_step: "Eliminar Paso",
    remove: "Remover",
    reserve_your_transfer_ticket: "Reserva tu ticket de traslado",
    reserve_your_transfer: "Reserva tu traslado ahora",
    reserved_tickets: "Ticket reservados",
    return_purchase_history: "Volver al Historial de Compra",
    routes_vmove: "Rutas Vmove",
    routes: "Rutas",
    rut_run: "RUT / RUN / Pasaporte",
    save_changes: "Guardar Cambios",
    saved_addresses: "Direcciones guardadas",
    search_results: "Resultados de la búsqueda",
    search: "Buscar",
    see_available_tickets: "Ver tickets disponibles",
    see_more: "Ver Más",
    see_partners: "Ver partners",
    see_video_tutorial: "Ver video tutorial",
    select_date: "Selecciona Fecha",
    select_province: "Selecciona Delegacion/Municipio",
    select_stop: "Seleccionar Parada",
    select_your_address: "Selecciona su dirección",
    select_your_category: "Selecciona tu categoría",
    select_your_payment_method: "Selecciona tu método de pago",
    share_event: "Compartir evento",
    show_event: "Ver evento",
    sign_up_here: "Regístrate aquí",
    sign_up: "Regístrate",
    start_session: "Iniciar Sesión",
    state: "Estado",
    street_and_number: "Calle y número",
    street_number_dept: "Calle, número, departamento",
    success_payment_email:
        "Hemos enviado una copia del comprobante de pago a tu email.",
    success_payment: "Tu pago se ha realizado exitosamente",
    successfully: "Satisfactoriamente",
    surnames: "Apellidos",
    take_your_event: "Te llevamos a tu evento",
    tell_us_about_you_marriage: "Cuéntanos sobre tu matrimonio",
    terms: "Términos",
    they_male: "Ellos",
    they_women: "Ellas",
    through_starting_point: "Pasamos por ti al punto de partida",
    ticket_detail: "Ver Ticket",
    ticket: "Ticket",
    to: "Hasta",
    total: "Total",
    transfer_from: "Traslado desde",
    transfer_to_mass_events: "Traslado a Eventos Masivos",
    transfer_to_private_events: "Traslado a Eventos Privados",
    unable_to_upload_your_application: "No es posible cargar su solicitud",
    under_review_events: "En revisión",
    vmove_companies: "Vmove Empresas",
    vmove_b2b: "Vmove B2B",
    vmove_persons: "Vmove Personas",
    vmove: "Vmove",
    waiting_for_you: "Te estábamos esperando",
    we_are: "Somos",
    we_transfer_users: "Hoy trasladamos a usuarios y personal de",
    we: "Nosotros",
    welcome: "Bienvenido",
    when_done_take_you_back: "Al terminar, te llevamos de vuelta",
    who_travels: "¿Quiénes viajan?",
    who_we_are: "¿Quiénes Somos?",
    wire_transfer_description1:
        "Debes realizar una transferencia bancaria a la cuenta que se indica a continuación",
    wire_transfer_description2:
        "Una vez realizada la transferencia, nuestros ejecutivos validaran el pago en menos de 24 horas. Te llegara un correo confirmando la recepcion del pago.",
    wire_transfer: "Transferencia bancaria",
    women_stag_party: "Despedida de Soltera",
    your_events: "Tus Eventos",
    your_home: "Tu Casa",
    you_dont_have_frequent_friends: "Aún no tienes amigos frecuentes registrados",
    you_dont_have_frequent_addresses:
        "Aún no tienes direcciones frecuentes registrados",
};

export const ES_MX = () => {
    return language;
};
