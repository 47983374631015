import { orangeColor } from 'assets/jss/style.js'
import { latoRegular } from 'assets/jss/tools.js'

export const purchaseStyles = {
  container: {
    margin: '0 10px',
  },
  textItemFormat: {
    color: '#7E7E7E',
    fontWeight: 400,
    fontSize: '16px',
    ':&hover': {
      cursor: 'default',
    },
  },
  iconButtonRoot: {
    ':&hover': {
      cursor: 'pointer',
    },
  },
  iconButtonColor: (props) => ({
    color: props.color,
  }),
  listItemRoot: {
    display: 'grid',
    gridTemplateColumns: '0.7fr 1fr 1fr 1fr 100px',
    textAlign: 'center',
    borderBottom: '1px solid #7E7E7E',
  },
  listItemRootMobile: {
    display: 'grid',
    gridTemplateColumns: '0.7fr 1fr 1fr 0.7fr 0.7fr',
    textAlign: 'center',
    borderBottom: '1px solid #7E7E7E',
  },
  boxCircularProgress: {
    height: "400px",
  },
}

export const purchaseListStyle = {
  textItemFormat: {
    color: '#FF5E05',
    fontWeight: 700,
    ':&hover': {
      cursor: 'default',
    },
  },
  listItemRoot: {
    display: 'grid',
    gridTemplateColumns: '0.7fr 1fr 1fr 1fr 100px',
    textAlign: 'center',
  },
  listItemRootMobile: {
    display: 'grid',
    gridTemplateColumns: '0.7fr 1fr 1fr 0.7fr 0.7fr',
    textAlign: 'center',
  },
  title: {
    color: '#7E7E7E',
    fontSize: '20px',
    fontWeight: 700,
    textAlign: 'right',
    margin: '5px 25px 30px 0',
  },
  titleDetail: {
    color: '#7E7E7E',
    fontSize: '20px',
    fontWeight: 700,
    margin: '0 25px 0 0',
  },
  buttonDetail: {
    borderRadius: '5px',
    color: '#f4511e',
    fontFamily: latoRegular,
    fontSize: '20px',
    opacity: '0.9',
    textTransform: 'initial',
    borderBottom: '1px solid rgba(0, 0, 0, 0)',
    transition: '0.3s',
    '&:hover,&:focus': {
      color: orangeColor,
      cursor: 'pointer !important',
      borderBottom: '1px solid',
      transition: '0.3s',
    },
  },
  container: {
    height: '500px',
    overflow: 'auto',
    overflowX: 'hidden',
    padding: '0 10px',
  },
}

export const ticketStateStyle = {
  state: (props) => ({
    backgroundColor: props.color,
    border: '1px solid transparent',
    color: 'white',
    margin: 'auto',
    width: '113px',
    height: '25px',
  }),
  stateResponsive: (props) => ({
    backgroundColor: props.color,
    border: '1px solid transparent',
    color: 'white',
    margin: 'auto',
    borderRadius: '50%',
    width: '20px',
    height: '20px',
  }),
}
