import React, { useContext, useRef, useEffect } from 'react'
import Context from '../../context/Context'
import styles from 'assets/jss/views/aboutUsPage.js'
import { makeStyles } from '@material-ui/core/styles'
import { useMediaQuery } from 'react-responsive'
import AboutUs from 'assets/img/vmove/about-us-page/about-us.png'
import Money from 'assets/img/vmove/icons/money.svg'
import Leaf from 'assets/img/vmove/icons/leaf.png'
import ReactPlayer from 'react-player'
// import LinkedInIcon from '@material-ui/icons/LinkedIn'
import Worker1 from 'assets/img/vmove/about-us-page/workers/v2/german_correa_h.jpg'
import Worker2 from 'assets/img/vmove/about-us-page/workers/v2/monica_gonzalez_c.jpg'
import Worker3 from 'assets/img/vmove/about-us-page/workers/v2/german_correa_p.jpg'
import Worker4 from 'assets/img/vmove/about-us-page/workers/v2/mikhael_marzuqa.jpg'
import Worker5 from 'assets/img/vmove/about-us-page/workers/v2/carlos_gonzalez_c.jpg'
import Worker6 from 'assets/img/vmove/about-us-page/workers/v2/vittorio_migliorini.jpg'
import Worker7 from 'assets/img/vmove/about-us-page/workers/v2/martin_dominguez.jpg'
import Worker8 from 'assets/img/vmove/about-us-page/workers/v2/katerina_Marzuqa.jpg'
import { Box, Container, Divider, Grid, Typography } from '@material-ui/core'

const useStyles = makeStyles(styles)

export default function PrivacidadPage(props) {
    const divRef = useRef(null)
    const contextType = useContext(Context)
    const { t } = contextType
    const classes = useStyles()
    const isMobile = useMediaQuery({ query: '(max-width: 480px)' })
    const customSpacing = isMobile ? 0 : 4

    useEffect(() => {
        const heightToScroll = divRef.current.offsetTop - 115
        window.scrollTo({
            top: heightToScroll,
            behavior: 'smooth',
        })
    }, [])

    return (
        <Box className={isMobile ? classes.bgWhite : null} classes={{ root: classes.contentWrapper }}>

            <Container ref={divRef} className={isMobile ? classes.containerDetailInfoMobile : classes.containerDetailInfo} style={{ paddingTop: 190 }}>


                <Grid container spacing={customSpacing} className={isMobile ? classes.gridContainerBodyMobile : classes.gridContainerBody}>

                    <Grid item xs={11} md={11} className={isMobile ? classes.mt2r : null}>
                        <Typography classes={{ root: classes.labelHeader }} className={isMobile ? classes.fs20 : classes.fs24}>
                            AVISO DE PRIVACIDAD Y PROTECCION DE DATOS
                        </Typography>
                        <Typography className={classes.labelBody + ' ' + classes.fs16 + ' ' + classes.mt1r}>
                            De conformidad con lo dispuesto por la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (en lo sucesivo la "Ley"), del Reglamento de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (en lo sucesivo el "Reglamento") y de los Lineamientos del Aviso de Privacidad (en lo sucesivo los "Lineamientos" y conjuntamente con la Ley y el Reglamento, la "Regulación de Privacidad") se emite el presente Aviso de Privacidad (en lo sucesivo el "Aviso de Privacidad") conforme a los siguientes términos y condiciones:<br />
                        </Typography>

                    </Grid>
                    <Grid item xs={11} md={11} className={isMobile ? classes.mt2r : null}>
                        <Typography classes={{ root: classes.labelHeader }} className={isMobile ? classes.fs20 : classes.fs24}>
                            A. RESPONSABLE DE LA PROTECCIÓN DE SUS DATOS PERSONALES
                        </Typography>
                        <Typography className={classes.labelBody + ' ' + classes.fs16 + ' ' + classes.mt1r}>
                            Vmove Latam, S.A. de C.V., (en lo sucesivo la "Sociedad") es responsable del tratamiento de sus datos personales, la cual tiene su domicilio en la Calle Paseo de la Reforma 296, Juarez, Cuauhtemoc, 06600, CDMX (en lo sucesivo el "Domicilio de la Sociedad"), su correo electrónico es: contacto@vmove.mx (en lo sucesivo el "Correo de la Sociedad") y su teléfono es el: 5561057480 (en lo sucesivo el "Teléfono de la Sociedad"). Igualmente, usted puede contactar a la persona encargada (en lo sucesivo el "Encargado") del Departamento de Datos Personales de la Sociedad en el (i) Correo de la Sociedad.
                            <br />
                        </Typography>

                    </Grid>
                    <Grid item xs={11} md={11} className={isMobile ? classes.mt2r : null}>
                        <Typography classes={{ root: classes.labelHeader }} className={isMobile ? classes.fs20 : classes.fs24}>
                            B. DATOS QUE SE RECABARÁN.
                        </Typography>
                        <Typography className={classes.labelBody + ' ' + classes.fs16 + ' ' + classes.mt1r}>
                            Datos de identificación:   <br />   <br />
                            Nombre completo.   <br />
                            Género.   <br />
                            Correo electrónico.   <br />
                            Teléfono.   <br />
                            Fecha de nacimiento.   <br />
                            Domicilio.   <br />
                        </Typography>

                    </Grid>

                    <Grid item xs={11} md={11} className={isMobile ? classes.mt2r : null}>
                        <Typography classes={{ root: classes.labelHeader }} className={isMobile ? classes.fs20 : classes.fs24}>
                            C. FINALIDAD CON QUE SE RECABARÁN Y UTILIZARÁN SUS DATOS PERSONALES.
                        </Typography>
                        <Typography className={classes.labelBody + ' ' + classes.fs16 + ' ' + classes.mt1r}>
                            Promoción de los servicios que comercializa la Sociedad, a través de boletines informativos.<br /><br />
                            Las operaciones, gestiones y procedimientos técnicos, ya sean realizados de forma automatizada como no automatizada, que posibiliten la recogida, almacenamiento, modificación, transferencia y otras acciones sobre datos de carácter personal, tienen la consideración de tratamiento de datos personales.<br /><br />
                            En la Sociedad tratamos los datos personales que el usuario nos facilita a través de www.vmove.mx con el fin de incluirlos en la agenda de contactos, gestionar la relación comercial solicitada y utilizar así esta información como medio de contacto de dicha empresa. Además, el tratamiento de estos datos recabados también implica gestionar el envío de la información que nos sea solicitada y poder responder a las consultas o cuestiones que el usuario remita a través del sitio web, facilitando a su vez a los interesados las ofertas de servicios que sean de su interés.<br /><br />
                            El usuario puede oponerse al envío de comunicaciones comerciales en cualquier momento mediante el envío de un mensaje de correo electrónico a la dirección indicada anteriormente.<br /><br />

                        </Typography>

                    </Grid>
                    <Grid item xs={11} md={11} className={isMobile ? classes.mt2r : null}>
                        <Typography classes={{ root: classes.labelHeader }} className={isMobile ? classes.fs20 : classes.fs24}>
                            D. A TRAVÉS DE QUE MEDIOS OBTENEMOS DATOS PERSONALES DE USTED.
                        </Typography>
                        <Typography className={classes.labelBody + ' ' + classes.fs16 + ' ' + classes.mt1r}>
                            Para las finalidades señaladas en el Aviso de Privacidad podemos recabar sus datos personales de distintas maneras, cuando (i) usted nos los proporciona directamente; (ii) usted visita nuestro sitio de Internet o utiliza nuestros servicios en línea; y (iii) obtenemos información a través de otras fuentes que están permitidas por la Regulación de Privacidad.<br /><br />
                            Dentro de las herramientas de captura automática de datos utilizadas por la Sociedad en sus sitios de Internet o servicios en línea se encuentran las cookies y las web beacons, las cuales sirven para recabar en que sitios de Internet de la Sociedad se realiza algún registro, la finalidad por la cual se recaban estos datos es para registrar el comportamiento de la navegación en línea.<br /><br />
                            Usted puede restringir, bloquear o borrar las cookies de los sitios de nuestro Aviso de Privacidad o cualquier otra página web, utilizando su navegador. En cada navegador la operativa es diferente, la función de Ayuda de su navegador le mostrará cómo hacerlo.<br /><br />

                        </Typography>

                    </Grid>

                    <Grid item xs={11} md={11} className={isMobile ? classes.mt2r : null}>
                        <Typography classes={{ root: classes.labelHeader }} className={isMobile ? classes.fs20 : classes.fs24}>
                            E. CONSENTIMIENTO TÁCITO DEL TRATAMIENTO DE SUS DATOS PERSONALES.
                        </Typography>
                        <Typography className={classes.labelBody + ' ' + classes.fs16 + ' ' + classes.mt1r}>
                            Si usted no manifiesta su oposición para que sus datos personales sean tratados, se entenderá que ha otorgado su consentimiento para ello. Sin embargo, en caso de que usted no consienta que sus datos personales sean tratados en los términos que señala el Aviso de Privacidad, favor de indicarlo en la opción respectiva en el momento en que se esté registrando como cliente de la Sociedad por el medio que corresponda.
                        </Typography>

                    </Grid>
                    <Grid item xs={11} md={11} className={isMobile ? classes.mt2r : null}>
                        <Typography classes={{ root: classes.labelHeader }} className={isMobile ? classes.fs20 : classes.fs24}>
                            DERECHOS DE LOS INTERESADOS
                        </Typography>
                        <Typography className={classes.labelBody + ' ' + classes.fs16 + ' ' + classes.mt1r}>
                            El usuario podrá ejercitar en todo momento, en los términos establecidos en la legislación vigente, los derechos de acceso, rectificación o supresión de datos, solicitar que se limite el tratamiento, oponerse al mismo, solicitar la portabilidad de sus datos, así como revocar el consentimiento prestado, derechos reconocidos en el citado Reglamento. El ejercicio de estos derechos puede realizarlo el propio usuario dirigiéndose a VMOVE LATAM S.A. de C.V. en la dirección de su sede social indicada anteriormente; a través del envío de un correo electrónico a la siguiente dirección contacto@vmove.mx<br /><br />
                            Para el efectivo ejercicio de estos derechos, el usuario deberá acreditar su identidad facilitando su nombre y apellidos, fotocopia del INE o documento identificativo equivalente que acredite su identidad, petición en la que se concrete la solicitud, dirección a efectos de notificaciones, y fecha y firma del solicitante.<br /><br />
                            Asimismo, el usuario podrá reclamar ante el Instituto Nacional de Transparencia, Acceso a la Información y Protección de Datos Personales (Autoridad de Control competente en esta materia), especialmente cuando no haya obtenido satisfacción en el ejercicio de sus derechos, mediante escrito dirigido a misma.<br /><br />

                        </Typography>

                    </Grid>
                    <Grid item xs={11} md={11} className={isMobile ? classes.mt2r : null}>
                        <Typography classes={{ root: classes.labelHeader }} className={isMobile ? classes.fs20 : classes.fs24}>
                            F. LIMITACIÓN DEL USO DE SUS DATOS PERSONALES.
                        </Typography>
                        <Typography className={classes.labelBody + ' ' + classes.fs16 + ' ' + classes.mt1r}>
                            Usted puede dejar de recibir información de cualquier naturaleza de la Sociedad, ya sea personalmente, a través de correo electrónico o de manera telefónica, dando una notificación al Encargado del Departamento de Datos Personales de la Sociedad en el (i) Correo de la Sociedad.<br /><br />
                            Para mayor información, favor de comunicarse con el Encargado del Departamento de Datos Personales de la Sociedad al (i) Correo de la Sociedad.
                        </Typography>

                    </Grid>
                    <Grid item xs={11} md={11} className={isMobile ? classes.mt2r : null}>
                        <Typography classes={{ root: classes.labelHeader }} className={isMobile ? classes.fs20 : classes.fs24}>
                            G. EJERCICIO DE SUS DERECHOS DE ACCESO, RECTIFICACIÓN, CANCELACIÓN Y OPOSICIÓN.
                        </Typography>
                        <Typography className={classes.labelBody + ' ' + classes.fs16 + ' ' + classes.mt1r}>
                            Usted tiene derecho a (i) acceder a sus datos personales que poseemos y a los detalles del tratamiento de los mismos; (ii) rectificar sus datos personales en caso de ser inexactos o incompletos; (iii) cancelar sus datos personales cuando considere que no se requieren para alguna de las finalidades señaladas en el Aviso de Privacidad, estén siendo utilizados para finalidades no consentidas o haya finalizado la relación comercial; u (iv) oponerse al tratamiento de sus datos personales para fines específicos (en lo sucesivo conjuntamente, los "Derechos ARCO").
                            <br /><br />
                            Los mecanismos que se han implementado para el ejercicio de los Derechos ARCO son a través de la presentación de la solicitud que le proporcione el Encargado del Departamento de Datos Personales de la Sociedad (i) a través del Correo de la Sociedad, conforme a lo siguiente:
                            <br /><br />
                            1. Su solicitud deberá contener la siguiente información: (i) El nombre y domicilio completos del titular de los datos personales, su correo electrónico u otro medio para comunicarle la respuesta a su solicitud; (ii) los documentos que acrediten la identidad o, en su caso, la representación legal del titular; (iii) la descripción clara y precisa de los datos personales respecto de los que se busca ejercer alguno de los Derechos ARCO; (iv) el lugar y la fecha en que se genera y entrega la solicitud de ejercicio de los Derechos ARCO a la Sociedad; (v) firma del titular de los datos personales; y (vi) referencia o indicación de la información original del titular de los datos personales. Lo anterior en el entendido de que si el titular de los datos personales no cumple con la entrega de lo establecido en los incisos del (i) al (vi) anteriores, su solicitud de ejercicio de los Derechos ARCO se tendrá por no solicitada a la Sociedad.<br /><br />
                            2. En un plazo máximo de 20 días hábiles atenderemos su solicitud y le informaremos de manera gratuita sobre la procedencia de la misma, en los términos de la Ley, a través de una notificación al titular de los datos personales a su (i) domicilio; o (ii) correo electrónico. En caso de resultar procedente su solicitud, esta deberá hacerse efectiva en un plazo máximo de 15 días hábiles a partir de la fecha en que se comunique la respuesta a la misma.<br /><br />
                            Para mayor información, favor de comunicarse con el Encargado del Departamento de Datos Personales de la Sociedad al (i) Correo de la Sociedad.


                        </Typography>

                    </Grid>
                    <Grid item xs={11} md={11} className={isMobile ? classes.mt2r : null}>
                        <Typography classes={{ root: classes.labelHeader }} className={isMobile ? classes.fs20 : classes.fs24}>
                            H. REVOCACIÓN DE CONSENTIMIENTO PARA EL TRATAMIENTO DE SUS DATOS PERSONALES.
                        </Typography>
                        <Typography className={classes.labelBody + ' ' + classes.fs16 + ' ' + classes.mt1r}>
                            En todo momento, usted podrá revocar el consentimiento que ha otorgado a la Sociedad para el tratamiento de sus datos personales, a fin de que dejemos de hacer uso de los mismos.
                            <br /><br />
                            Su solicitud deberá ser presentada (i) a través del Correo de la Sociedad.
                            <br /><br />
                            Asimismo, su solicitud deberá acompañarse de la siguiente información: (i) El nombre y domicilio completos del titular de los datos personales, su correo electrónico u otro medio para comunicarle la respuesta a su solicitud; (ii) los documentos que acrediten la identidad o, en su caso, la representación legal del titular; (iii) la descripción clara y precisa de los datos personales respecto de los que se busca revocar el consentimiento que se ha otorgado a la Sociedad para el tratamiento de los mismos; (iv) el lugar y la fecha en que se genera y entrega la solicitud de revocación de consentimiento a la Sociedad para el tratamiento de los datos personales; (v) firma del titular de los datos personales; y (vi) referencia o indicación de la información original del titular de los datos personales. Lo anterior en el entendido de que si el titular de los datos personales no cumple con la entrega de lo establecido en los incisos del (i) al (vi) anteriores, su solicitud de revocación de consentimiento se tendrá por no solicitada a la Sociedad.
                            <br /><br />
                            En un plazo máximo de 20 días hábiles atenderemos su solicitud y le informaremos de manera gratuita sobre la procedencia de la misma, en los términos de la Ley, a través de una notificación al titular de los datos personales a su (i) domicilio; o (ii) correo electrónico. En caso de resultar procedente su solicitud, esta deberá hacerse efectiva en un plazo máximo de 15 días hábiles a partir de la fecha en que se comunique la respuesta a la misma.
                            <br /><br />
                            Para mayor información, favor de comunicarse con el Encargado del Departamento de Datos Personales de la Sociedad al (i) Correo de la Sociedad.
                        </Typography>

                    </Grid>

                    <Grid item xs={11} md={11} className={isMobile ? classes.mt2r : null}>
                        <Typography classes={{ root: classes.labelHeader }} className={isMobile ? classes.fs20 : classes.fs24}>
                            I. SEGURIDAD DE LA INFORMACIÓN.
                        </Typography>
                        <Typography className={classes.labelBody + ' ' + classes.fs16 + ' ' + classes.mt1r}>
                            La Sociedad como empresa responsable del tratamiento y resguardo de los datos personales de los titulares, cuenta con procedimientos físicos, electrónicos y administrativos para proteger la información personal contra destrucción accidental o legal, pérdida o alteración ilegal y divulgación o acceso no autorizado.<br />

                        </Typography>

                    </Grid>
                    <Grid item xs={11} md={11} className={isMobile ? classes.mt2r : null}>
                        <Typography classes={{ root: classes.labelHeader }} className={isMobile ? classes.fs20 : classes.fs24}>
                            J. MODIFICACIONES AL AVISO DE PRIVACIDAD
                        </Typography>
                        <Typography className={classes.labelBody + ' ' + classes.fs16 + ' ' + classes.mt1r}>
                            Nos reservamos el derecho de efectuar en cualquier momento modificaciones o actualizaciones al Aviso de Privacidad, para la atención de reformas legislativas, novedades jurisprudenciales, políticas internas o nuevos requerimientos para el ofrecimiento de nuestros productos y prácticas del mercado.
                            <br /><br />
                            Estas modificaciones estarán disponibles al público a través de nuestra página de Internet, en la sección de Aviso de Privacidad.
                        </Typography>

                    </Grid>
                    <Grid item xs={11} md={11} className={isMobile ? classes.mt2r : null}>
                        <Typography classes={{ root: classes.labelHeader }} className={isMobile ? classes.fs20 : classes.fs24}>
                            K. LEGISLACIÓN APLICABLE, JURISDICCIÓN Y COMPETENCIA.
                        </Typography>
                        <Typography className={classes.labelBody + ' ' + classes.fs16 + ' ' + classes.mt1r}>
                            El Aviso de Privacidad se rige por la Regulación de Privacidad y las demás disposiciones federales legales aplicables vigentes en México. La aceptación del Aviso de Privacidad implica su sometimiento expreso a los tribunales competentes en la Ciudad de México, México, para cualquier controversia o reclamación derivada del mismo.
                        </Typography>

                    </Grid>
                    <Grid item xs={11} md={11} className={isMobile ? classes.mt2r : null}>
                        <Typography classes={{ root: classes.labelHeader }} className={isMobile ? classes.fs20 : classes.fs24}>
                            L. PROPIEDAD INTELECTUAL E INDUSTRIAL
                        </Typography>
                        <Typography className={classes.labelBody + ' ' + classes.fs16 + ' ' + classes.mt1r}>
                            Todos los contenidos del sitio web, entre otros, textos, fotografías, gráficos, imágenes, iconos, tecnología, software, links y demás contenidos audiovisuales, así como su diseño gráfico y códigos fuente, constituyen una obra cuya propiedad pertenece a la Sociedad, sin que puedan entenderse cedidos al usuario ninguno de los derechos de explotación sobre los mismos más allá de lo estrictamente necesario para el correcto uso de la web, y con independencia de que sean susceptibles o no de propiedad intelectual. <br /><br />
                            Asimismo, todas las marcas, nombres comerciales o signos distintivos de cualquier clase que aparecen en el sitio web son propiedad de la Sociedad, sin que pueda entenderse que el uso o acceso al mismo atribuya al usuario derecho alguno sobre los mismos. <br /><br />
                            Queda prohibida la reproducción total o parcial, explotación, distribución, modificación, cesión o comunicación pública de los contenidos y cualquier otro acto que no haya sido expresamente autorizado por el titular de los derechos de explotación. Cualquier uso no autorizado previamente se considera un incumplimiento grave de los derechos de propiedad intelectual o industrial del autor. <br /><br />
                            Para realizar cualquier tipo de observación respecto a posibles incumplimientos de los derechos de propiedad intelectual o industrial, así como sobre cualquiera de los contenidos del sitio web, el usuario deberá notificar dicha circunstancia a la Sociedad adjuntando la información pertinente. <br /><br />
                            En cualquier caso, la Sociedad no asume responsabilidad alguna respecto de los derechos de propiedad intelectual o industrial titularidad de terceros que se vean infringidos por un tercero o por el usuario. <br /><br />


                        </Typography>

                    </Grid>
                    <Grid item xs={11} md={11} className={isMobile ? classes.mt2r : null}>
                        <Typography classes={{ root: classes.labelHeader }} className={isMobile ? classes.fs20 : classes.fs24}>
                            M. ENLACES
                        </Typography>

                        <Typography className={classes.labelBody + ' ' + classes.fs16 + ' ' + classes.mt1r}>
                            El sitio web de la Sociedad puede contener enlaces o hipervínculos a otros sitios de Internet, respecto a los cuales no ejerce ningún tipo de control. Por tanto, una vez que el usuario acceda a los enlaces de terceros y abandone el sitio web, tanto el presente Aviso Legal, como la Política de Privacidad y la Política de Cookies dejarán de surtir efecto, ya que los sitios web a los que el usuario acceda están sujetos a sus propias políticas.
                            <br /><br />
                            La Sociedad no puede asumir responsabilidad alguna por el contenido que pueda aparecer en páginas de terceros, ni garantiza la disponibilidad técnica, calidad, fiabilidad, exactitud, amplitud, veracidad, validez y constitucionalidad de cualquier material o información contenida en ninguno de dichos enlaces o hipervínculos. Asimismo, la inclusión de estas conexiones externas a sitios web ajenos no implica ningún tipo de asociación, fusión o participación con las entidades conectadas.


                        </Typography>

                    </Grid>
                    <Grid item xs={11} md={11} className={isMobile ? classes.mt2r : null}>

                        <Typography classes={{ root: classes.labelHeader }} className={isMobile ? classes.fs20 : classes.fs24}>
                            N. TERMINOS Y CONDICIONES DE COMPRA
                        </Typography>
                        <Typography className={classes.labelBody + ' ' + classes.fs16 + ' ' + classes.mt1r}>
                            El perfeccionamiento, finalización o realización de la compra de boletos a través del sistema de la Sociedad está sujeta a la comprobación de los datos personales y de la tarjeta proporcionados por el cliente y a la autorización por parte del banco emisor de la tarjeta de crédito o débito cuyos datos ha proporcionado el Cliente para el pago de los boletos solicitados o por parte del banco aceptante. Si los datos personales o de la tarjeta de crédito proporcionados por el Cliente no coinciden con los datos a disposición del banco emisor de la tarjeta de crédito o débito o, aun coincidiendo los datos en cuestión, el banco emisor o el banco aceptante no autorizan el cargo solicitado por el Cliente, la compra no será procesada ni finalizada.
                            <br /><br />

                            Sin perjuicio de lo anterior, el Cliente expresamente reconoce y acepta que la solicitud de compra de los boletos que realiza a través del sistema de la Sociedad es una oferta vinculante y que una vez recibida la autorización del banco emisor de la tarjeta de crédito o débito correspondiente o del banco aceptante, la operación de compra será definitiva y no estará sujeta a cambios, reembolsos, devoluciones o cancelaciones.<br />
                            En virtud de lo anterior, el Cliente expresamente reconoce y acepta que no tendrá derecho a cambios, reembolsos, devoluciones o cancelaciones. El Cliente expresamente reconoce y acepta que sólo tendrá derecho al reembolso del costo de los boletos, en caso de que se cancele el evento para el cual adquirió los boletos.
                            <br /><br />
                            El reembolso se llevará a cabo, por el valor nominal del boleto y envío durante los 30 días siguientes a la cancelación del evento, además aplicarán las instrucciones establecidas por las instituciones bancarias en las compras efectuadas con tarjetas bancarias.
                            <br /><br />
                            Los supuestos de posposición del evento son responsabilidad exclusiva del organizador y en su caso por orden de la autoridad competente, liberando a la Sociedad de cualquier responsabilidad, por lo que los boletos adquiridos serán válidos para la nueva fecha programada o en su caso, procede el reembolso del valor nominal del boleto previa solicitud del cliente al momento en que sea informada por el organizador del evento al público en general la nueva fecha, a través del  (i) Correo de la Sociedad.
                        </Typography>

                    </Grid>
                    <Grid item xs={11} md={11} className={isMobile ? classes.mt2r : null}>

                        <Typography classes={{ root: classes.labelHeader }} className={isMobile ? classes.fs20 : classes.fs24}>
                            O. EXCLUSIÓN DE GARANTÍAS Y RESPONSABILIDAD

                        </Typography>
                        <Typography className={classes.labelBody + ' ' + classes.fs16 + ' ' + classes.mt1r}>
                            La información suministrada por del presente sitio web es de carácter general y tiene una finalidad meramente informativa, sin que se garantice plenamente el acceso a todos los contenidos, ni su exactitud, exhaustividad, corrección, vigencia o actualidad, ni su idoneidad o utilidad para un objetivo específico.
                            <br /><br />
                            La Sociedad no se hace responsable, en ningún caso, de los daños y perjuicios de cualquier naturaleza derivados de, a título enunciativo: <br /><br />
                            - Lista sin orden Errores u omisiones en los contenidos. La Sociedad no garantiza que los contenidos vayan a estar actualizados permanentemente, ni que carezcan de cualquier tipo de error. <br /><br />
                            - Lista sin orden Ausencia de disponibilidad del portal. La Sociedad no se hace responsable de los posibles daños o perjuicios generados en el usuario como consecuencia de fallos o desconexiones en las redes de telecomunicaciones que supongan la suspensión, cancelación o interrupción del servicio del sitio web, ya que el funcionamiento de estas redes depende de terceros. <br /><br />
                            - Lista sin orden Presencia de virus o programas maliciosos o lesivos en los contenidos que puedan alterar los sistemas informáticos, documentos electrónicos o datos de los usuarios, a pesar de haber adoptado todas las medidas tecnológicas necesarias para prevenirlo. Corresponde al usuario, en todo caso, estar provisto de herramientas adecuadas que le protejan ante programas informáticos dañinos. <br /><br />


                        </Typography>

                    </Grid>
                    <Grid item xs={11} md={11} className={isMobile ? classes.mt2r : null}>


                        <Typography className={classes.labelBody + ' ' + classes.fs16 + ' ' + classes.mt1r}>
                            Fecha de la última actualización del Aviso de Privacidad: 27 de enero de 2023.
                        </Typography>

                    </Grid>






                </Grid>

            </Container>
        </Box>
    )
}
