import React, { Fragment, useState, useContext, useEffect } from "react";
import ServiceIncludes from "./_serviceIncludes.js";
import Context from "../../context/Context";
import TicketReservation from "./_ticketReservation.js";
import Moment from "react-moment";
import {
  Box,
  Container,
  Grid,
  Typography,
  CircularProgress,
  createMuiTheme,
  Button,
} from "@material-ui/core";
import { Link, Redirect, useParams } from "react-router-dom";
import styles from "assets/jss/views/eventPageSections/detailStyle.js";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import orange from "@material-ui/core/colors/orange";
import { useMediaQuery } from "react-responsive";
import LoginSelector from "../../components/Header/InfoBar/LoginSelector";
import { getEventBySlug } from "provider/publicProvider/provider";
import { getInvitedEventById } from "provider/privateProvider/provider";

import AuthCheck from "utils/authCheck";

const useStyles = makeStyles(styles);

export default function Detail(props) {
  const [eventDetail, setEventDetail] = useState({});
  const [error, setError] = useState(false);
  const [translations, setTranslations] = useState({});

  const contextType = useContext(Context);
  let { getLogStatus, setLogStatus, getLanguage, t, setDiscount } = contextType;
  const classes = useStyles();
  const isMobile = useMediaQuery({ query: "(max-width: 959px)" });
  const [id, setId] = useState();
  let { name } = useParams();
  let slug = name;
  const { place, calendars, periodicityType } = eventDetail;
  const [eventDate, setEventDate] = useState({ startDate: "", endDate: "" });
  const [paymentType, setPaymentType] = useState(1);
  const [invitedErrorMessage, setInvitedErrorMessage] = useState("");
  const [loader, setLoader] = useState(true);
  const [isLogged, setIsLogged] = useState(false);
  const [eventAvariable, setEventAvariable] = useState(true);

  let eventIdentify;

  useEffect(() => {
    setDiscount([])
  }, [])

  useEffect(() => {
    setError(false);
    setLoader(true);
    slug ? (eventIdentify = slug) : (eventIdentify = id);
    AuthCheck()
      .then((responseAuth) => {
        getInvitedEventById(eventIdentify)
          .then((res) => {
            res.message
              ? setInvitedErrorMessage(res.message)
              : setInvitedErrorMessage("");
            setId(res.id);
            setEventDetail(res);
            setEventDate({
              startDate: res.startDate.replace(/-/g, "/"),
              endDate: res.endDate.replace(/-/g, "/"),
            });
            setTranslations(res.translations);
            setPaymentType(res.paymentType);
          })
          .catch((error) => {
            setError(true);
          })
          .finally(() => {
            setLoader(false);
          });
      })
      .catch((errorAuth) => {
        getEventBySlug(eventIdentify)
          .then((res) => {
            if (res.message !== "" && res.message !== undefined) {
              setInvitedErrorMessage(res.message);
            } else {
              setId(res.id);
              setEventDetail(res);
              setEventDate({
                startDate: res.startDate.replace(/-/g, "/"),
                endDate: res.endDate.replace(/-/g, "/"),
              });
              setTranslations(res.translations);
              setPaymentType(res.paymentType);
            }
          })
          .catch((error) => {
            setEventAvariable(false);
            setError(true);
          })
          .finally(() => {
            setLoader(false);
          });
      });
  }, [getLogStatus()]);

  useEffect(() => {
    const enableStepperFooter = () => {
      AuthCheck()
        .then((res) => {
          setIsLogged(true);
          setLogStatus(true);
        })
        .catch((e) => {
          setIsLogged(false);
          setLogStatus(false);
        });
    };
    enableStepperFooter();
  }, [getLogStatus()]);

  const formattedDate = (eventDate) => {
    const newFormatDate = eventDate.replace(/-/g, " ");

    return newFormatDate;
  };

  const dateMomentFormat = (eventDate, format) => {
    const momentFormat = (
      <Moment format={format}>{formattedDate(eventDate)}</Moment>
    );

    return momentFormat;
  };

  const dateLocaleString = (eventDate, type) => {
    const date = new Date(Date.parse(formattedDate(eventDate)));
    const localeDate = date.toLocaleString(getLanguage().replace("_", "-"), {
      [type]: "long",
    });

    return localeDate.charAt(0).toUpperCase() + localeDate.slice(1);
  };

  const defaultMaterialTheme = createMuiTheme({
    palette: {
      primary: {
        main: orange["700"],
        dark: orange["800"],
        contrastText: "#FFFFFF",
      },
    },
  });

  if (invitedErrorMessage) {
    return (
      <Box
        className={
          isMobile ? classes.contentWrapperMobile : classes.contentWrapper
        }
        style={{ paddingBottom: "20%", textAlign: "center" }}
      >
        <Typography
          className={isMobile ? classes.fs18 : classes.fs20}
          style={{ paddingTop: "20%" }}
        >
          {invitedErrorMessage}
          {!isLogged && <LoginSelector isEvent={true} />}
        </Typography>
      </Box>
    );
  }

  if (!eventAvariable) {
    return (
      <Box
        className={
          isMobile ? classes.contentWrapperMobile : classes.contentWrapper
        }
        style={{ paddingBottom: "10%", textAlign: "center" }}
      >
        <Typography
          className={isMobile ? classes.fs18 : classes.fs20}
          style={{ paddingTop: "10%" }}
        >
          {"El evento ya no está disponible"}
          <Grid item xs={12} md={12} className={classes.itemFullCenter}>
            <Link to="/">
              <Button
                classes={{ root: classes.buttonBack }}
                variant="contained"
                className={isMobile ? classes.fs16 : classes.fs18}
              >
                {t("back_to_main_screen")}
              </Button>
            </Link>
          </Grid>
        </Typography>
      </Box>
    );
  }

  if (loader) {
    return (
      <Box
        className={
          isMobile ? classes.contentWrapperMobile : classes.contentWrapper
        }
      >
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          className={classes.boxWrapper}
        >
          <ThemeProvider theme={defaultMaterialTheme}>
            <Grid
              item
              xs={12}
              md={12}
              classes={classes.boxCircularProgress}
              style={{
                height: "500px",
                alignItems: "center",
                paddingTop: "5%",
                paddingBottom: "5%",
              }}
            >
              <CircularProgress />
            </Grid>
          </ThemeProvider>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      className={
        isMobile ? classes.contentWrapperMobile : classes.contentWrapper
      }
    >
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        className={classes.boxWrapper}
      >
        {translations[getLanguage()] !== undefined ? (
          <img
            src={translations[getLanguage()].imagePath}
            alt={translations[getLanguage()].name}
            className={classes.imgFluid}
          />
        ) : null}
      </Box>
      <Container
        className={
          isMobile
            ? classes.containerDetailInfoMobile
            : classes.containerDetailInfo
        }
      >
        <Grid
          container
          className={
            isMobile
              ? classes.gridContainerDetailInfoMobile
              : classes.gridContainerDetailInfo
          }
        >
          <Grid item xs={12} md={12}>
            {place !== undefined ? (
              <Grid
                container
                className={classes.containerContentInfo}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Grid item xs={12} md={3} className={classes.itemFullCenter}>
                  <img
                    src={place.imagePath}
                    alt={place.name}
                    className={
                      isMobile ? classes.logoArenaMobile : classes.logoArena
                    }
                  />
                </Grid>
                <Grid item xs={12} md={9}>
                  <Typography
                    classes={{ root: classes.paragraphHeaderInfo }}
                    className={
                      isMobile
                        ? classes.fs16 + " " + classes.mb1r
                        : classes.fs18 + " " + classes.mb1r
                    }
                  >
                    <Box className={classes.boxDetailWrapper}>
                      {eventDetail.periodicityType === 1 ? (
                        <>
                          <Typography
                            className={isMobile ? classes.fs18 : classes.fs20}
                          >
                            {dateLocaleString(eventDate.startDate, "weekday")}
                          </Typography>
                          <Typography
                            className={isMobile ? classes.fs25 : classes.fs50}
                          >
                            {dateMomentFormat(eventDate.startDate, "DD")}{" "}
                            {dateLocaleString(eventDate.startDate, "month")}
                          </Typography>
                        </>
                      ) : (
                        <Typography
                          className={isMobile ? classes.fs25 : classes.fs50}
                        >
                          {"Elige tu fecha"}
                        </Typography>
                      )}
                      <Typography
                        className={isMobile ? classes.fs30 : classes.fs60}
                      >
                        {translations[getLanguage()] !== undefined
                          ? translations[getLanguage()].name
                          : null}
                      </Typography>
                      {place !== undefined ? (
                        <Fragment>
                          <Typography
                            key={place.id}
                            className={isMobile ? classes.fs18 : classes.fs20}
                          >
                            {eventDetail.periodicityType === 1 ? (
                              <>
                                {place.translations[getLanguage()].name} /{" "}
                                {dateMomentFormat(eventDate.startDate, "HH:mm")}{" "}
                                {t("hours")}
                              </>
                            ) : (
                              <>{place.address.split(",")[0]}</>
                            )}
                          </Typography>
                        </Fragment>
                      ) : null}
                      {eventDetail.additionalStops !== undefined ? eventDetail.additionalStops.map((stop, index) => {
                        return (
                          <Fragment>
                            <Typography
                              key={stop.id}
                              className={isMobile ? classes.fs18 : classes.fs20}
                            >
                              <>
                                {stop.address} /{" "}
                                {stop.time.substring(0, 5)}{" "}
                                {t("hours")}
                              </>
                            </Typography>
                          </Fragment>
                        )
                      }) : null}
                    </Box>
                  </Typography>
                  <Typography
                    classes={{ root: classes.paragraphHeaderInfo }}
                    className={isMobile ? classes.fs16 : classes.fs18}
                  ></Typography>
                </Grid>
              </Grid>
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                classes={{ root: classes.boxCustomProgress }}
              >
                <CircularProgress
                  classes={{ root: classes.customProgressLarge }}
                />
              </Box>
            )}
            <Box
              className={
                isMobile
                  ? classes.boxServicesIncludesMobile
                  : classes.boxServicesIncludes
              }
            >
              <ServiceIncludes />
            </Box>
            <Box>
              {id && (
                <TicketReservation
                  periodicityType={periodicityType}
                  calendars={calendars}
                  eventDate={eventDate}
                  paymentType={paymentType}
                  slug={slug}
                  eventId={id}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </Container>
      {error ? <Redirect to="/error404" /> : null}
    </Box>
  );
}
