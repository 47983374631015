import React, { useContext } from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery } from "react-responsive";
import Context from "context/Context";
import CustomCircularProgress from "components/CircularProgress/CustomCircularProgress";
import styles from "assets/jss/views/eventPageSections/generalEventsStyles.js";
import TicketDetails from "components/TicketDetails/TicketDetails";
import Webpay from "components/Payment/PaymentMethods/Webpay";
import Unlimit from "components/Payment/PaymentMethods/Unlimit";
import Mercadopago from "components/Payment/PaymentMethods/Mercadopago";
import Wiretransfer from "components/Payment/PaymentMethods/Wiretransfer";
import { WiretransferMobile } from "components/Payment/PaymentMethods/Wiretransfer";

const useStyles = makeStyles(styles);

const FinishTab = (props) => {
  const { paymentResponse, paymentMethod, tickets, paymentType } = props;
  const contextType = useContext(Context);
  const { t } = contextType;
  const isMobile = useMediaQuery({ query: "(max-width: 959px)" });
  const classes = useStyles();

  const renderPayment = () => {
    switch (paymentMethod) {
      case "webpay":
        return <Webpay paymentResponse={paymentResponse} />;
      case "unlimit":
        return <Unlimit paymentResponse={paymentResponse} />;
      case "unlimit_paypal":
        return <Unlimit paymentResponse={paymentResponse} />;
      case "unlimit_oxxo":
        return <Unlimit paymentResponse={paymentResponse} />;
      case "unlimit_spei":
        return <Unlimit paymentResponse={paymentResponse} />;
      case "unlimit_codi":
        return <Unlimit paymentResponse={paymentResponse} />;
      case "unlimit_mercadopago":
        return <Unlimit paymentResponse={paymentResponse} />;
      case "mercadopago":
        return <Mercadopago paymentResponse={paymentResponse} />;
      case "transferencia":
        return isMobile ? (
          <WiretransferMobile paymentResponse={paymentResponse} />
        ) : (
          <Wiretransfer paymentResponse={paymentResponse} />
        );
      default:
        return <h1>Error</h1>;
    }
  };

  return !paymentResponse ? (
    <Grid container className={classes.loader}>
      <CustomCircularProgress size={60} />
    </Grid>
  ) : (
    <>
      <Grid justify="space-between" alignItems="center" container>
        <Typography
          classes={{ root: classes.addressTitle }}
          className={isMobile ? classes.fs18 : classes.fs20}
        >
          {paymentType === 1 && t("wire_transfer")}
        </Typography>
      </Grid>

      <TicketDetails tickets={tickets} />
      {paymentType === 1 && (
        <>
          {renderPayment()}
        </>
      )}

      {paymentType === 2 && (
        <>
          <Typography
            align='center'
            classes={{ root: classes.invitedEventPayment }}
            className={isMobile ? classes.fs20 : classes.fs25}
            style={{ paddingTop: '3%', paddingBottom: '3%' }}
          >
            {'Su viaje ha sido reservado con exito. En unos momentos le va a llegar un mail con el detalle de la reserva.'}
          </Typography>
        </>
      )}

      {paymentType === 1 && (
        <>
          <Grid container className={classes.loader}>
            <CustomCircularProgress size={60} />
          </Grid>
        </>
      )}
    </>
  );
};

export default FinishTab;
