import React from "react";
import * as ol from "openlayers";
import MarkerPin from "assets/img/vmove/icons/marker.png";
import { control, Controls, layer, Layers, Map } from "react-openlayers";

export default function CustomMap(props) {
  //const coords = new ol.proj.fromLonLat([-70.61810, -33.42684]);
  const coords = new ol.proj.fromLonLat([-99.1613011, 19.4305696]);
  const marker = new ol.Feature(new ol.geom.Point(coords));
  const markerStyle = new ol.style.Style({
    image: new ol.style.Icon({
      src: MarkerPin
    })
  });
  marker.setStyle(markerStyle);

  const source = new ol.source.Vector({ features: [marker] });

  return (
    <Map
      view={{
        height: "268",
        center: coords,
        zoom: 16
      }}
    >
      <Layers>
        <layer.Tile source={new ol.source.OSM()} />
        <layer.Vector source={source} zIndex="1" />
      </Layers>
      <Controls attribution={false} zoom={true}>
        <control.Rotate />
        <control.ScaleLine />
        <control.FullScreen />
        <control.OverviewMap />
        <control.Zoom />
      </Controls>
    </Map>
  );
}
