const carouselStyle = {
  carouselWrapper: {
    borderRadius: '0 !important',
    height: '100%',
    //maxHeight: ' 650px',
    marginBottom: '0 !important',
    marginTop: '0 !important',
  },
  cover: {
    marginTop: '120px',
    // backgroundPosition: "center",
    // backgroundRepeat: "no-repeat",
    // backgroundSize: "cover",
    height: 'auto',
    // maxWidth: "100%",
    // overflow: "hidden",
    // position: "relative",
    width: '100%',
  },
  coverImage: {
    width: '100%',
    height: '100%',
    // height: "calc(100vh - 120px)",
  },
}

export default carouselStyle
