import React, { useContext, useEffect, useState } from "react";
import styles from "assets/jss/components/cardPrivateEventList.js";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  Container,
  CardContent,
  CircularProgress,
  Grid,
  createMuiTheme,
} from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import orange from "@material-ui/core/colors/orange";
import { getInvitedEvents } from "provider/privateProvider/provider.js";
import moment from "moment";
import { Link } from "react-router-dom";
import Context from "../../context/Context";

const useStyles = makeStyles(styles);

const InvitedPrivateEventList = ({ linkToEvent }) => {
  const classes = useStyles();

  const [dataList, setDataList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [errorResponse, setErrorResponse] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const contextType = useContext(Context);
  const { t } = contextType;

  useEffect(() => {
    getInvitedEvents()
      .then((data) => {
        setDataList(data);
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage(t("unable_to_upload_your_application"));
        setErrorResponse(true);
        console.error("error active_private_event_list", error);
      })
      .finally(() => {
        setLoader(false);
      });
  }, []);

  const defaultMaterialTheme = createMuiTheme({
    palette: {
      primary: {
        main: orange["700"],
        dark: orange["800"],
        contrastText: "#FFFFFF",
      },
    },
  });

  if (loader) {
    return (
      <Container>
        <ThemeProvider theme={defaultMaterialTheme}>
          <Grid
            item
            xs={12}
            md={12}
            classes={classes.boxCircularProgress}
            style={{ height: "500px", alignItems: "center", paddingTop: "25%" }}
          >
            <CircularProgress />
          </Grid>
        </ThemeProvider>
      </Container>
    );
  }

  return (
    <>
      {!errorResponse ? (
        dataList.message ? (
          <div className={classes.divContainer}>
            <Grid
              container
              xs={12}
              md={12}
              justify="center"
              className={classes.msgError}
            >
              {t("no_events_available")}
            </Grid>
          </div>
        ) : (
          dataList.map((item) => (
            <div className={classes.divContainer}>
              <Card variant="outlined" className={classes.root}>
                <Grid item md={8} xs={12}>
                  <div className={classes.details}>
                    <CardContent className={classes.content}>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        className={classes.dateContent}
                      >
                        {moment(item.startDate, "MM/DD/YYYY").format(
                          "DD/MM/yyyy"
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        className={classes.titleContentInvited}
                      >
                        {/* <p className={classes.titleContentInvited}>
                          {item.name}
                        </p> */}
                        {/* <Link
                          to={{
                            pathname: `/event/${item.name.replace(/ /g, "-")}`,
                            state: { id: item.eventId, slug: item.slug },
                          }}
                        > */}
                        <Link
                          to={{
                            pathname: `/event/${item.eventSlug}`, state: { slug: item.eventSlug, id: item.id },
                          }}
                        >
                          <p className={classes.titleContentInvited}>
                            {item.name}
                          </p>
                        </Link>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        className={classes.addressContent}
                      >
                        {item.address}
                      </Grid>
                    </CardContent>
                  </div>
                </Grid>
                <Grid item md={4} xs={12}>
                  <div className={classes.divStatusInvited}>
                    {t("event_invited")}
                  </div>
                  {item.imagePath && (
                    <img
                      src={item.imagePath}
                      className={classes.cover}
                      alt={item.name}
                    />
                  )}
                </Grid>
              </Card>
            </div>
          ))
        )
      ) : (
        <div className={classes.divContainer}>
          <Grid
            container
            xs={12}
            md={12}
            justify="center"
            className={classes.msgError}
          >
            {errorMessage}
          </Grid>
        </div>
      )}
    </>
  );
};

export default InvitedPrivateEventList;
