import React, { useState, useContext } from 'react'
import Context from '../../context/Context'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import VmoveCompanies from 'assets/img/vmove/icons/vmove_companies.svg'
import CloseIcon from '@material-ui/icons/Close'
import Alliances from 'assets/img/vmove/icons/alliances.svg'
import Marriage from 'assets/img/vmove/icons/marriage.svg'
import PrivateEvents from 'assets/img/vmove/icons/private_events.svg'
import MassiveEvents from 'assets/img/vmove/icons/massive_events.svg'
import LoginSelector from '../../components/Header/InfoBar/LoginSelector'
import LogoMobile from 'assets/img/vmove/logos/vmove-logo-mobile.svg'
import SearchIcon from '@material-ui/icons/Search'
import LanguageSelector from '../../components/Header/LanguageSelector'
import MenuIcon from '@material-ui/icons/Menu'
import styles from 'assets/jss/components/contentBarMobileStyle.js'
import UserMobile from 'assets/img/vmove/icons/user-mobile.svg'
import SearchForm from './InfoBar/SearchForm'

import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import {
  AppBar,
  Box,
  Button,
  Collapse,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from '@material-ui/core'

const useStyles = makeStyles(styles)

export default function ContentBarMobile(props) {
  const [drawer, setDrawer] = useState(false)
  const [openSearch, setOpenSearch] = useState(false)
  const classes = useStyles()
  const contextType = useContext(Context)
  const { t } = contextType

  const handleDrawer = (action) => (event) => {
    setDrawer(action)
  }

  const list = () => (
    <Box
      className={classes.customPaperDrawer}
      role='presentation'
    // onClick={this.handleDrawer(anchor, false)}
    // onKeyDown={this.handleDrawer(anchor, false)}
    >
      {' '}
      <Box classes={{ root: classes.boxActions }} display='flex' justifyContent='center' flexDirection='column' alignItems='center'>
        <Box alignItems='center' classes={{ root: classes.boxUserIcon }} display='flex' justifyContent='center' width='100%'>
          <img alt='Mobile User' className={classes.imageUser} src={UserMobile} />
          <Box classes={{ root: classes.boxBackgroundUser }} />
        </Box>
        <LoginSelector />
        <Button classes={{ root: classes.buttonClose }} onClick={handleDrawer(false)}>
          <CloseIcon />
        </Button>
      </Box>
      <Box alignItems='center' classes={{ root: classes.boxLanguageSelector }} display='flex' justifyContent='center'>
        <Grid container justify='center'>
          <LanguageSelector />
        </Grid>
      </Box>
      <Divider classes={{ root: classes.customDivider }} />
      <List classes={{ root: classes.customList }}>
        <ListItem button onClick={() => setDrawer(false)}>
          <Link to='/massive-events' className={classes.customLink}>
            <ListItemIcon>
              <img alt='MassiveEvents' className={classes.customItemIcon} src={MassiveEvents} />
            </ListItemIcon>
            <ListItemText classes={{ root: classes.customItemText }}>{t('massive_events')}</ListItemText>
          </Link>
        </ListItem>
        <ListItem button onClick={() => setDrawer(false)}>
          <Link to='/private-events' className={classes.customLink}>
            <ListItemIcon>
              <img alt='PrivateEvents' className={classes.customItemIcon} src={PrivateEvents} />
            </ListItemIcon>
            <Typography classes={{ root: classes.customItemText }}>{t('private_events')}</Typography>
          </Link>
        </ListItem>
        {/* <ListItem button onClick={() => setDrawer(false)}>
          <Link to="/routes" className={classes.customLink}>
            <ListItemIcon classes={{ root: classes.customItemIcon }}>
              <img alt ="MapPin" src={MapPin} />
            </ListItemIcon>
            <Typography classes={{ root: classes.customItemText }}>
              {t("routes")}
            </Typography>
          </Link>
        </ListItem> */}
        <ListItem button onClick={() => setDrawer(false)}>
          <Link to='/wedding' className={classes.customLink}>
            <ListItemIcon>
              <img alt='Marriage' className={classes.customItemIcon} src={Marriage} />
            </ListItemIcon>
            <Typography classes={{ root: classes.customItemText }}>{t('boyfriends')}</Typography>
          </Link>
        </ListItem>
        <ListItem button onClick={() => setDrawer(false)}>
          <Link to='/alliances' className={classes.customLink}>
            <ListItemIcon>
              <img alt='Alliances' className={classes.customItemIcon} src={Alliances} />
            </ListItemIcon>
            <Typography classes={{ root: classes.customItemText }}>{t('alliances')}</Typography>
          </Link>
        </ListItem>
        <ListItem button onClick={() => setDrawer(false)}>
          <Link to='/vmove-companies' className={classes.customLink}>
            <ListItemIcon>
              <img alt='VmoveCompanies' className={classes.customItemIcon} src={VmoveCompanies} />
            </ListItemIcon>
            <Typography classes={{ root: classes.customItemText }}>{t('vmove_b2b')}</Typography>
          </Link>
        </ListItem>
      </List>
      <Divider classes={{ root: classes.customDivider }} />
      <List classes={{ root: classes.customList }}>
        <ListItem button onClick={() => setDrawer(false)}>
          <Link to='/how-book'>
            <Typography classes={{ root: classes.customItemText }}>¿{t('how_to_book')}?</Typography>
          </Link>
        </ListItem>
        <ListItem button onClick={() => setDrawer(false)}>
          <Link to='/about-us'>
            <Typography classes={{ root: classes.customItemText }}>¿{t('about_us')}?</Typography>
          </Link>
        </ListItem>
        <ListItem button onClick={() => setDrawer(false)}>
          <Link to='/contact'>
            <Typography classes={{ root: classes.customItemText }}>{t('contact')}</Typography>
          </Link>
        </ListItem>
      </List>
    </Box>
  )

  return (
    <Box classes={{ root: classes.boxHeader }}>
      <AppBar position='static' classes={{ root: classes.appbarMobile }}>
        <Toolbar classes={{ root: classes.toolbarMobile }}>
          <Box display='flex' alignItems='center' justifyContent='space-between' width='100%'>
            <Button onClick={handleDrawer(true)}>
              <IconButton edge='start' className={classes.menuButton} aria-label='menu'>
                <MenuIcon />
              </IconButton>
            </Button>
            <Drawer anchor='left' open={drawer} onClose={handleDrawer(false)} classes={{ docked: classes.customPaperDrawer }}>
              {list()}
            </Drawer>
            <Link to='/'>
              <img alt='Mobile Logo' src={LogoMobile} />
            </Link>
            <Button onClick={() => setOpenSearch(!openSearch)}>
              <IconButton edge='start' className={classes.menuButton} aria-label='menu'>
                {openSearch ? <HighlightOffIcon /> : <SearchIcon />}
              </IconButton>
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <Collapse in={openSearch}>
        <SearchForm />
      </Collapse>
    </Box>
  )
}
