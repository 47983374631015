import React, { useState, useContext, useEffect } from "react";
import Context from "../../context/Context";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { Box, Button, Container, Grid, Typography } from "@material-ui/core";
import ReservationTabs from "./_reservationTabs.js";
import styles from "assets/jss/views/eventPageSections/detailStyle.js";
import { useMediaQuery } from "react-responsive";
import { Redirect } from "react-router-dom";

//Calendar
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import orange from "@material-ui/core/colors/orange";
import EventIcon from "@material-ui/icons/Event";
import SelectComponent from "components/Tools/SelectComponent";
import {
  getGroupsByEvent,
  getProvinceByEvent,
} from "provider/publicProvider/provider";
import CustomCircularProgress from "components/CircularProgress/CustomCircularProgress";

const useStyles = makeStyles(styles);

export default function TicketReservation(props) {
  // Categories
  const [categories, setCategories] = useState([]);
  // Discount Categories
  const [categoriesWithDiscount, setCategoriesWithDiscount] = useState([]);
  // Current Global Step
  const [currentStep, setCurrentStep] = useState(0);
  const [error, setError] = useState(false);
  // Counties
  const [citySelected, setCitySelected] = useState({});
  const [oldCitySelected, setOldCitySelected] = useState({});
  const [countiesList, setCountiesList] = useState([]);
  // Dates
  const [dateSelectedPicker, setDateSelectedPicker] = useState(new Date());
  const [dateSelected, setDateSelected] = useState({});

  const contextType = useContext(Context);
  const classes = useStyles();
  //const { id } = useParams()

  const { getCalendarLanguage, getCountry, t, getDiscount } = contextType;
  const isMobile = useMediaQuery({ query: "(max-width: 959px)" });

  const buttonDateOption = isMobile
    ? classes.buttonDateOptionMobile
    : classes.buttonDateOption;

  const { periodicityType, calendars, eventDate, paymentType, eventId } = props;

  useEffect(() => {
    if (calendars) {
      periodicityType !== 2
        ? setDateSelected(formarOneDate(calendars[0]))
        : setDateSelectedPicker(eventDate.startDate);
    }
  }, [calendars, eventDate, periodicityType]);
  useEffect(() => {
    setCategoriesWithDiscount(getDiscount());
  }, [getDiscount()]);

  const defaultMaterialTheme = createMuiTheme({
    palette: {
      primary: {
        main: orange["700"],
        dark: orange["800"],
        contrastText: "#FFFFFF",
      },
    },
  });

  const formarCalendar = () => {
    if (calendars) {
      const date = calendars.map((calendar) => {
        const { id, startDate } = calendar;
        return { id, startDate: dateFormatPicker(startDate) };
      });
      return date;
    }
  };

  const formarOneDate = (objectDate) => {
    if (objectDate) {
      const { id, startDate } = objectDate;
      const newDate = moment(startDate, "MM-DD-YYYY HH:mm").format(
        "DD-MM-YYYY HH:mm"
      );
      return { id, startDate: newDate };
    }
  };

  const formarDate = (objectDate) => {
    if (objectDate) {
      const newDate = moment(objectDate, "MM-DD-YYYY HH:mm").format(
        "DD-MM-YYYY HH:mm"
      );
      return newDate;
    }
  };

  const dateFormatPicker = (date) => {
    if (date) {
      if (periodicityType === 2) {
        return moment(date, "MM-DD-YYYY HH:mm").format("DD-MM-YYYY HH:mm");
      } else {
        let newDate = new Date(Date.parse(date.split("-").join("/")));
        return moment(newDate, "MM-DD-YYYY HH:mm").format("DD-MM-YYYY HH:mm");
      }
    }
  };

  const getCategories = (city) => {
    getGroupsByEvent(eventId)
      .then((res) => {
        setCategories(res);
      })
      .catch((error) => {
        setError(true);
      });
  };

  const getCounties = () => {
    getProvinceByEvent(eventId)
      .then((res) => {
        getProvinceNames(res);
      })
      .catch((error) => {
        setError(true);
      });
  };

  const handleDateButtonClick = () => (event) => {
    event.preventDefault();
    document.getElementById(`datePicker`).click();
  };

  const getProvinceNames = (countiesList) => {
    const result = countiesList.map((province) => {
      const { name, id } = province.Province;
      return {
        id,
        name,
        counties: province.Counties,
      };
    });
    setCountiesList(result);
    setCitySelected(result[0]);
    getCategories(result[0]);
  };

  const setCitySelectedAndOld = (value) => {
    setOldCitySelected(citySelected);
    setCitySelected(value);
  };

  useEffect(() => {
    eventId && getCounties();
  }, [getCountry()]);

  useEffect(() => {
    if (currentStep === 1) setOldCitySelected({});
  }, [currentStep]);

  return (
    <Container>
      {currentStep < 4 && (
        <Grid container>
          <Grid item xs={12} md={6}>
            <Typography
              classes={{ root: classes.serviceTitle }}
              className={isMobile ? classes.fs20 : classes.fs25}
            >
              {t("reserve_your_transfer_ticket")}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            className={isMobile ? classes.itemButtonMobile : classes.itemButton}
          >
            <Box
              display="flex"
              justifyContent={isMobile ? "center" : "flex-end"}
              alignItems="center"
              className={isMobile ? classes.boxButtonMobile : classes.boxButton}
            >
              <Box
                className={
                  isMobile
                    ? classes.boxOptionSelectMobile
                    : classes.boxOptionSelect
                }
              >
                {t("select_date")}
              </Box>
              {periodicityType === 2 ? (
                <>
                  <ThemeProvider theme={defaultMaterialTheme}>
                    <MuiPickersUtilsProvider
                      utils={DateFnsUtils}
                      locale={getCalendarLanguage()}
                    >
                      <DateTimePicker
                        autoOk
                        ampm={false}
                        value={dateSelectedPicker}
                        minDate={moment(
                          eventDate.startDate,
                          "MM-DD-YYYY HH:mm"
                        ).format("YYYY-MM-DD HH:mm")}
                        onChange={setDateSelectedPicker}
                        maxDate={moment(
                          eventDate.endDate,
                          "MM-DD-YYYY HH:mm"
                        ).format("YYYY-MM-DD HH:mm")}
                        format="dd/MM/yyyy HH:MM"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        style={{ display: "none" }}
                        id={"datePicker"}
                      />
                    </MuiPickersUtilsProvider>
                  </ThemeProvider>
                  <Button
                    className={isMobile && classes.w50}
                    classes={{
                      root: buttonDateOption,
                    }}
                    disabled={currentStep !== 0}
                    disableUnderline
                    onClick={handleDateButtonClick()}
                    style={{ justifyContent: "space-between" }}
                    endIcon={
                      <EventIcon
                        className={classes.custButtonDatetIconSelect}
                      />
                    }
                  >
                    <Typography variant="inherit" noWrap={true}>
                      {dateFormatPicker(dateSelectedPicker)}
                    </Typography>
                  </Button>
                </>
              ) : (
                <SelectComponent
                  selected={dateSelected}
                  setSelected={setDateSelected}
                  currentStep={currentStep}
                  data={formarCalendar()}
                  label={"startDate"}
                />
              )}
            </Box>
            <Box
              display="flex"
              justifyContent={isMobile ? "center" : "flex-end"}
              alignItems="center"
              className={isMobile ? classes.boxButtonMobile : classes.boxButton}
            >
              <Box
                className={
                  isMobile
                    ? classes.boxOptionSelectMobile
                    : classes.boxOptionSelect
                }
              >
                {t("select_province")}
              </Box>
              <SelectComponent
                selected={citySelected}
                setSelected={setCitySelectedAndOld}
                currentStep={currentStep}
                data={countiesList}
                label={"name"}
              />
            </Box>
          </Grid>
        </Grid>
      )}
      <Grid item xs={12}>
        {error ? (
          <Redirect to="/error404" />
        ) : (
          <>
            {categories.length > 0 &&
              Object.entries(citySelected).length > 0 ? (
              <ReservationTabs
                dateSelectedPicker={
                  periodicityType !== 2
                    ? dateSelected.startDate
                    : formarDate(dateSelectedPicker)
                }
                citySelected={citySelected}
                oldCitySelected={oldCitySelected}
                setOldCitySelected={setOldCitySelected}
                setCurrentStep={setCurrentStep}
                category={categories}
                paymentType={paymentType}
                eventId={eventId}
                discounts={categoriesWithDiscount}
              />
            ) : (
              <CustomCircularProgress />
            )}
          </>
        )}
      </Grid>
    </Container>
  );
}
