import React, { useContext, useRef, useEffect } from 'react'
import Context from '../../context/Context'
import styles from 'assets/jss/views/aboutUsPage.js'
import { makeStyles } from '@material-ui/core/styles'
import { useMediaQuery } from 'react-responsive'
import AboutUs from 'assets/img/vmove/about-us-page/about-us.png'
import Money from 'assets/img/vmove/icons/money.svg'
import Leaf from 'assets/img/vmove/icons/leaf.png'
import ReactPlayer from 'react-player'
// import LinkedInIcon from '@material-ui/icons/LinkedIn'
import Worker1 from 'assets/img/vmove/about-us-page/workers/v2/german_correa_h.jpg'
import Worker2 from 'assets/img/vmove/about-us-page/workers/v2/monica_gonzalez_c.jpg'
import Worker3 from 'assets/img/vmove/about-us-page/workers/v2/german_correa_p.jpg'
import Worker4 from 'assets/img/vmove/about-us-page/workers/v2/mikhael_marzuqa.jpg'
import Worker5 from 'assets/img/vmove/about-us-page/workers/v2/carlos_gonzalez_c.jpg'
import Worker6 from 'assets/img/vmove/about-us-page/workers/v2/vittorio_migliorini.jpg'
import Worker7 from 'assets/img/vmove/about-us-page/workers/v2/martin_dominguez.jpg'
import Worker8 from 'assets/img/vmove/about-us-page/workers/v2/katerina_Marzuqa.jpg'
import { Box, Container, Divider, Grid, Typography } from '@material-ui/core'

const useStyles = makeStyles(styles)

export default function TerminosPage(props) {
  const divRef = useRef(null)
  const contextType = useContext(Context)
  const { t } = contextType
  const classes = useStyles()
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' })
  const customSpacing = isMobile ? 0 : 4

  useEffect(() => {
    const heightToScroll = divRef.current.offsetTop - 115
    window.scrollTo({
      top: heightToScroll,
      behavior: 'smooth',
    })
  }, [])

  return (
    <Box className={isMobile ? classes.bgWhite : null} classes={{ root: classes.contentWrapper }}>

      <Container ref={divRef} className={isMobile ? classes.containerDetailInfoMobile : classes.containerDetailInfo} style={{ paddingTop: 190 }}>
        <Box classes={{ root: classes.boxTitle }} display='flex' width='100%' justifyContent='center'>
          <Typography align='center' classes={{ root: classes.labelTitle }} className={isMobile ? classes.fs25 : classes.fs50}>
            Términos - Condiciones y Aviso de privacidad de Pasajeros.
          </Typography>
        </Box>
        <Divider classes={{ root: classes.customDivider }} />
        <Grid container spacing={customSpacing} className={isMobile ? classes.gridContainerBodyMobile : classes.gridContainerBody}>

          <Grid item xs={11} md={11} className={isMobile ? classes.mt2r : null}>
            <Typography classes={{ root: classes.labelHeader }} className={isMobile ? classes.fs20 : classes.fs24}>
              INTRODUCCIÓN:
            </Typography>
            <Typography className={classes.labelBody + ' ' + classes.fs16 + ' ' + classes.mt1r}>
              Vmove Latam S.A. de C.V., RFC VLA221021GK3, en adelante indistintamente “Vmove”, domiciliado en Paseo de la Reforma 296, Juarez, Cuauhtemoc, 06600, CDMX.<br />
              Vmove es una empresa que presta un servicio de transporte personalizado, entre tu evento y tu casa, obteniendo una mayor coordinación y conexión con su conductor, permitiendo experimentar exclusividad y eficiencia en el servicio.<br />
              El Pasajero acepta todos los términos y condiciones establecidos en el presente documento.<br />
            </Typography>

          </Grid>
          <Grid item xs={11} md={11} className={isMobile ? classes.mt2r : null}>
            <Typography classes={{ root: classes.labelHeader }} className={isMobile ? classes.fs20 : classes.fs24}>
              DECLARACIONES DE LOS PASAJEROS:
            </Typography>
            <Typography className={classes.labelBody + ' ' + classes.fs16 + ' ' + classes.mt1r}>
              Al descargar y/o usar Vmove usted manifiesta y garantiza lo siguiente:<br />
              Que es plenamente capaz y que, estando en pleno ejercicio de su capacidad es su voluntad obligarse en los términos aquí establecidos.<br />
              Que está en la posibilidad y cuenta con los recursos suficientes para pagar los servicios. <br />
              Que la información que proporcione a Vmove será veraz y precisa.
              <br />
            </Typography>

          </Grid>
          <Grid item xs={11} md={11} className={isMobile ? classes.mt2r : null}>
            <Typography classes={{ root: classes.labelHeader }} className={isMobile ? classes.fs20 : classes.fs24}>
              AUTORIZACIÓN DE USO DE TICKETERA, REQUERIMIENTOS TÉCNICOS Y RESTRICCIONES DE USO
            </Typography>
            <Typography className={classes.labelBody + ' ' + classes.fs16 + ' ' + classes.mt1r}>
              Durante la vigencia del presente Contrato, el Pasajero tendrá el derecho no exclusivo, gratuito, revocable e intransferible de utilizar Vmove exclusivamente para las funciones que se establecen en este contrato.<br />
              El Pasajero recibirá una contraseña vía correo electrónico, de modo que será el único autorizado para acceder Vmove mediante tal contraseña. El Pasajero será el único responsable de la conservación y confidencialidad de su contraseña y se compromete a no comunicarla bajo ninguna circunstancia a ningún otro pasajero, y a no utilizar la cuenta, el nombre o la contraseña de ningún otro Pasajero. Queda expresamente establecido que el uso de la contraseña constituye su aceptación incondicional de los términos y condiciones del presente contrato.<br />
              El Pasajero acepta y reconoce que Vmove podrá realizar acciones legales para requerir y asegurar el cumplimiento de todas las disposiciones establecidas en el presente contrato, así como solicitarle el resarcimiento por daños y perjuicios ocasionados por incumplimiento de sus obligaciones conforme a las leyes aplicables. Este Contrato en su integridad representa el acuerdo de voluntades entre Vmove y el Pasajero. <br />
              El Pasajero no incurrirá en ningún acto de discriminación hacia el Conductor por razones religión, género, orientación sexual, raza, etnia, edad, discapacidad, entre otros durante la prestación de los Servicios. <br />
              En caso de que exista la presunción de que el Pasajero hubiera violado la legislación o infringido los derechos de terceros mediante el uso Vmove, Vmove estará plenamente facultado a facilitar, previa solicitud de cualquier autoridad competente (incluyendo autoridades administrativas y judiciales), cualquier información que permita o facilite el esclarecimiento de los hechos presuntamente infractores o constitutivos de delito. En el supuesto que el afectado sea un Conductor, Vmove facilitará los datos a su alcance con el fin de que el afectado pueda iniciar las acciones legales correspondientes, condición que es aceptada por el Pasajero al aceptar estos Términos y Condiciones.<br />

            </Typography>

          </Grid>

          <Grid item xs={11} md={11} className={isMobile ? classes.mt2r : null}>
            <Typography classes={{ root: classes.labelHeader }} className={isMobile ? classes.fs20 : classes.fs24}>
              ACASO DE RECLAMOS O SOLICITUDES.
            </Typography>
            <Typography className={classes.labelBody + ' ' + classes.fs16 + ' ' + classes.mt1r}>
              En caso de que los Pasajeros tengan algún inconveniente con los Servicios prestados por los Conductores, podrán reportar dichos inconvenientes a Vmove, a través de la página www.vmove.mx De igual manera, podrán mantener contacto a través del correo contacto@vmove.mx Vmove realizará sus mejores esfuerzos para que entre los Pasajeros y los Conductores se puedan resolver los inconvenientes de manera amistosa y directa.<br />
              El Pasajero podrá recibir información del Conductor, como su nombre, vehículo y número de placas, para efecto de identificación en caso de que el Pasajero desee presentar una aclaración o requiera ver los detalles de un viaje.<br />
              Las ticketereas externas no tiene ninguna relación, participación injerencia sobre las políticas de Vmove, todo lo que implique la prestación del servicio de transporte y la relación con los pasajeros son de responsabilidad de Vmove. Se reitera que las ticketeras solo tienen la venta y distribución de Tickets, NO son una empresa de transporte, ni tampoco contrata al seguro automotriz, todo esto es de responsabilidad y realizado únicamente por Vmove.<br />

            </Typography>

          </Grid>
          <Grid item xs={11} md={11} className={isMobile ? classes.mt2r : null}>
            <Typography classes={{ root: classes.labelHeader }} className={isMobile ? classes.fs20 : classes.fs24}>
              RESPONSABILIDAD POR PÉRDIDA DE OBJETOS.
            </Typography>
            <Typography className={classes.labelBody + ' ' + classes.fs16 + ' ' + classes.mt1r}>
              Por medio del presente, Vmove hace del conocimiento de los Pasajeros las responsabilidades asumidas como empresa dedicada al rubro de transporte. En ese sentido, nos comprometemos a colaborar brindándoles la información que esté a nuestra disposición respecto a los Conductores sobre posibles objetos perdidos durante los Servicios de traslado, siempre que no exista impedimento legal para ello. Asimismo, les recordamos que, de acuerdo a lo estipulado en el presente contrato, Vmove queda exento de cualquier responsabilidad civil, penal o administrativa por la pérdida de cualquier objeto perteneciente al Pasajero.<br />
              En el caso de que tengamos en custodia algún objeto y/o elemento entregado en nuestras oficinas por Conductores, estos se encontrarán a disposición de sus propietarios por los siguientes plazos:<br />
              Tratándose de objetos perecederos, se desecharán inmediatamente después de recibirlos en nuestras oficinas.<br />
              Tratándose de objetos ilegales, por seguridad de los empleados de Vmove y de sus Conductores, se entregarán a las autoridades inmediatamente después de recibirlos en nuestras oficinas, entregando la información que para tal efecto soliciten.<br />
              Tratándose de todos los demás objetos olvidados, se desecharán en un plazo de 30 (treinta) días después de recibirlos en nuestras oficinas si el Pasajero no los reclama dentro de dicho plazo.<br />
              Vmove se reserva el derecho de solicitar al Pasajero algún medio probatorio (factura de compra u otro pertinente) que pueda demostrar la propiedad del elemento, en caso de que éste sea un artículo de valor.<br />

            </Typography>

          </Grid>

          <Grid item xs={11} md={11} className={isMobile ? classes.mt2r : null}>
            <Typography classes={{ root: classes.labelHeader }} className={isMobile ? classes.fs20 : classes.fs24}>
              Responsabilidad por protocolos de salud.
            </Typography>
            <Typography className={classes.labelBody + ' ' + classes.fs16 + ' ' + classes.mt1r}>
              Vmove cumplirá todas las normativas que establezca la autoridad en caso de alguna emergencia de salud en el país como pandemia u otro. Por su parte será obligación del cliente mantener las medidas de resguardo que ordena la ley, decreto o minsal, y que le competan a su responsabilidad, de no cumplirse lo ordenado al cliente no se realizará el servicio, lo que no tendrá derecho a devolución, ejemplo cliente sin mascarilla o con altas temperaturas de fiebre.
            </Typography>

          </Grid>
          <Grid item xs={11} md={11} className={isMobile ? classes.mt2r : null}>
            <Typography classes={{ root: classes.labelHeader }} className={isMobile ? classes.fs20 : classes.fs24}>
              Alcance de la Política de Privacidad
            </Typography>
            <Typography className={classes.labelBody + ' ' + classes.fs16 + ' ' + classes.mt1r}>
              Esta Política de Privacidad y tratamiento de datos personales, aplica para la recolección y tratamiento de datos e información personal almacenado en Vmove.<br />
              Vmove se reserva el derecho de modificar esta Política de Privacidad y será responsabilidad del Pasajero leerla y actuar de acuerdo a ella dicha modificación solo operara para el futuro, aquellos que hubiesen contratado este servicio con una determinada política se mantendrá la vigente a la fecha de su contratación.<br />
              Esta política de privacidad tiene por objeto proporcionarle una visión clara de cómo usamos la información personal que usted proporciona, nuestros esfuerzos por protegerla, sus derechos y las opciones que tiene para controlar su información personal y proteger su privacidad. <br /><br />


              Vmove, protegerá la confidencialidad de los datos personales, así como de toda la información de los clientes, como información contable y comunicaciones personales hasta el grado máximo de protección de acuerdo con la legislación vigente. Con este objetivo Vmove utiliza tecnología de seguridad para la producción y comunicación, así como también para el almacenamiento de la misma. Toda la información personal y profesional recibida en nuestros sistemas es automáticamente procesada y su almacenamiento es realizado en un entorno seguro y confiable con todas las condiciones técnicas necesarias para prevenir cualquier tipo de abuso.<br />
              Todas las comunicaciones (texto, audio y video) entre Vmove y sus pasajeros, se encuentra completamente encriptada y no puede ser accesible por terceras partes debido al sistema de seguridad HTTPS. Cuando un pasajero ingresa a Vmove, está accediendo a un dominio seguro y confidencial, donde todas las comunicaciones se conducen utilizando el protocolo HTTPS. De esta forma aseguramos que la información intercambiada entre los servidores de Vmove y los ordenadores de los usuarios (PCs, Smartphones o Tablets), se encuentra codificada.<br />

            </Typography>

          </Grid>
          <Grid item xs={11} md={11} className={isMobile ? classes.mt2r : null}>
            <Typography classes={{ root: classes.labelHeader }} className={isMobile ? classes.fs20 : classes.fs24}>
              Responsable de sus Datos Personales.
            </Typography>
            <Typography className={classes.labelBody + ' ' + classes.fs16 + ' ' + classes.mt1r}>
              Vmove Latam S.A. de C.V., con domicilio en Paseo de la Reforma 296, Juarez, Cuauhtemoc, 06600, CDMX. será el único responsable del tratamiento, uso, almacenamiento y divulgación de sus datos personales, conforme a lo establecido en el presente Aviso de Privacidad.<br />
              Datos Personales que recaba y almacena Vmove.<br />
              Los datos personales que podrá recabar serán:<br />
              Nombre completo;<br />
              Dirección postal de contacto;<br />
              Edad, así como lugar y fecha de nacimiento;<br />
              Fecha de registro;<br />
              Número de teléfono fijo y/o teléfono celular;<br />
              Correo electrónico;<br />
              Foto y/o imagen de perfil en redes sociales.<br />
              Imágenes y audio que identifican o hacen identificable al titular de dichos datos personales (únicamente aplicable para el Servicio Vmove).<br />
              Grabación, procesamiento, almacenamiento, transmisión de imágenes y reconstrucción por medios electrónicos digitales o analógicos, sobre la experiencia prestada por Vmove. <br />
              Dirección física, fecha y hora en el que el Pasajero solicita un Servicio de traslado;<br />
              Dirección física, fecha y hora en la que el Pasajero aborda un vehículo para un Servicio solicitado a través de Vmove así como el destino al cual se dirige, el trayecto y la ruta por la cual se dirige a dicho destino y la dirección física en la que el Pasajero culmine el Servicio.<br />
              Servicios solicitados y Servicios completados a través de Vmove;<br />
              Ubicación del teléfono móvil, celular o tableta en tiempo real lo que puede revelar la ubicación del Pasajero en tiempo real (Geolocalización y otras fuentes que proveen información relevante como dispositivos cercanos o puntos de acceso a Wi-Fi), datos que serán válidos desde que se solicita el pedido hasta que el Conductor o Pasajero presionan fin de viaje. Adicionalmente, el celular deberá encontrarse encendido y con el Servicio de GPS activo, pero no necesariamente se tiene que encontrar Vmove abierta en pantalla;<br />
              Calificaciones, opiniones o comentarios que el o Pasajero haga de Conductores o terceros; y
              Los datos técnicos de llamadas y mensajes entre el Pasajero y el Conductor, siempre y cuando se realicen por Vmove.<br />
              Los mensajes entre el Pasajero y el Conductor, siempre y cuando se realicen por Vmove.<br />
              Dirección de Protocolo de Internet (IP) que permita detectar y prevenir conductas fraudulentas.<br />
              El Pasajero podrá compartir su viaje con terceros, siendo bajo su responsabilidad la entrega de dicha información.<br />

            </Typography>

          </Grid>
          <Grid item xs={11} md={11} className={isMobile ? classes.mt2r : null}>
            <Typography classes={{ root: classes.labelHeader }} className={isMobile ? classes.fs20 : classes.fs24}>
              Tratamiento y uso de los datos personales
            </Typography>
            <Typography className={classes.labelBody + ' ' + classes.fs16 + ' ' + classes.mt1r}>
              Los datos personales con los que contará Vmove se administrarán y se tratarán para las siguientes finalidades que son estrictamente necesarias para el cumplimiento de la relación jurídico-comercial que el Pasajero mantiene con Vmove de acuerdo con sus funcionalidades. Usted manifiesta aceptar y dar consentimiento libre, previo, expreso, inequívoco e informado para el tratamiento de sus datos personales a favor de Vmove para las siguientes finalidades primarias:<br />
              Facilitar tanto al Conductor como al Pasajero, un medio de intercomunicación por el cual, los Pasajeros que requieran del Servicio de pasajeros con chofer, puedan coincidir en la prestación de los Servicios.<br />
              Permitir la coordinación y el reconocimiento por parte del Conductor cuando el Pasajero solicite un Servicio a través Vmove.<br />
              Medir la calidad de los Servicios de los Conductores que el Pasajero haya contratado y canalizar adecuadamente sus solicitudes de Servicio.<br />
              Analizar y evaluar sus características y cumplimiento como Pasajero.<br />
              Monitorear el debido cumplimiento contractual entre el Conductor y el Pasajero;<br />
              Atender cualquier queja, pregunta o comentario del Pasajero.<br />
              Para compartir las evaluaciones, calificaciones, comentarios u opiniones que terceros hagan respecto del Pasajero con pasajeros potenciales que cuenten con la Ticketera de Pasajeros.<br />
              Enviar notificaciones relacionadas con las modificaciones que se realicen al presente Aviso de Privacidad.<br />
              Detectar y prevenir conductas fraudulentas.<br />
              Para el seguimiento y atención de cualquier presunto incidente reportado como “emergencia” por el Conductor del viaje solicitado por el Pasajero, específicamente, en lo concerniente a los incidentes reportados como presunto: agresión física, agresión sexual, accidente vehicular, robo, hurto, objeto olvidado y delito contra la vida. En dichos supuestos, el Pasajero acepta compartir su identificación oficial y teléfono celular en favor del Conductor que reporta este hecho, a efectos que este último pueda ejercer su derecho a la tutela judicial efectiva.<br />
              Vmove no comunicará ni cederá a terceros los datos personales de sus pasajeros sin el consentimiento expreso del(los) titular(es). No obstante, lo anterior, si así lo requiere judicialmente, se entregará la información solicitada.
              <br />

            </Typography>

          </Grid>
          <Grid item xs={11} md={11} className={isMobile ? classes.mt2r : null}>
            <Typography classes={{ root: classes.labelHeader }} className={isMobile ? classes.fs20 : classes.fs24}>
              Divulgación de Datos Personales
            </Typography>
            <Typography className={classes.labelBody + ' ' + classes.fs16 + ' ' + classes.mt1r}>
              La información personal de quienes contrataran este servicio está protegida por la Ley Federal de Protección de Datos en Posesión de los Particulares (y sus actualizaciones), Vmove, no divulgará dicha información al Cliente o a terceros, a menos que dicha entrega sea autorizada al efecto por su titular o autorizada por la Ley. Vmove y en su caso el Cliente deben informar el tratamiento de datos, por lo que deberán entregar la posibilidad de que el consumidor acceda, rectifique, modifique o cancele sus datos personales al correo contacto@vmove.mx<br />
              Vmove no comparte, vende o distribuye la información personal y profesional de sus pasajeros con terceros, excepto:<br />
              - Para proveer al pasajero de alguna información relacionada a nuestra plataforma y/o sus servicios y productos, <br />
              - Cuando sean ocasionalmente compartidos con otras compañías relacionadas a Vmove, o terceros relacionados que actúen en representación de Vmove para los mismos propósitos para los cuales dicha información ha sido recolectada y descritos en esta Política. <br />
              - Cuando así sea requerido por la autoridad local<br />
              - Para proteger la seguridad e integridad de nuestras bases de datos de esta plataforma.<br />
              -  En el evento de nuestra venta, fusión, reorganización, disolución o algún evento similar.<br /><br />
              Vmove, informará al pasajero de cualquiera de estas transferencias o divulgaciones requeridas por la ley.<br />


            </Typography>

          </Grid>

          <Grid item xs={11} md={11} className={isMobile ? classes.mt2r : null}>
            <Typography classes={{ root: classes.labelHeader }} className={isMobile ? classes.fs20 : classes.fs24}>
              Acceso a la información y correcciones
            </Typography>
            <Typography className={classes.labelBody + ' ' + classes.fs16 + ' ' + classes.mt1r}>
              El usuario o cliente puede actualizar cualquier información hasta 72 horas antes del evento, a través de este correo electrónico contacto@vmove.mx. <br />



            </Typography>

          </Grid>
          <Grid item xs={11} md={11} className={isMobile ? classes.mt2r : null}>
            <Typography classes={{ root: classes.labelHeader }} className={isMobile ? classes.fs20 : classes.fs24}>
              LEY APLICABLE Y JURISDICCIÓN
            </Typography>
            <Typography className={classes.labelBody + ' ' + classes.fs16 + ' ' + classes.mt1r}>
              La ley aplicable será la mexicana, la que regirá cualquier controversia o reclamo relacionado con la política de privacidad. El pasajero acepta irrevocablemente y se somete a la jurisdicción personal de tribunales chilenos para todos los efectos. Sin perjuicio de lo anterior, Vmove se reserva el derecho de entablar acciones legales en cualquier jurisdicción donde considere que se ha producido u originado un incumplimiento de este acuerdo. <br />



            </Typography>

          </Grid>
          <Grid item xs={11} md={11} className={isMobile ? classes.mt2r : null}>
            <Typography classes={{ root: classes.labelHeader }} className={isMobile ? classes.fs20 : classes.fs24}>
              PLAZO Y TERMINACIÓN
            </Typography>
            <Typography className={classes.labelBody + ' ' + classes.fs16 + ' ' + classes.mt1r}>
              Este contrato permanecerá vigente por tiempo indefinido. El Pasajero o Vmove podrán darlo por terminado en cualquier momento, mediante simple notificación por correo electrónico, o por escrito entregada a la otra parte con al menos 24 (veinticuatro) horas de anticipación a la terminación, salvo en los casos de flagrancia en los cuales el contrato se dará por terminado inmediatamente. La terminación del contrato implicará que el derecho y autorización del Pasajero para utilizar Vmove quedará sin efecto, por lo que éste se compromete a eliminar Vmove de su teléfono celular, móvil o tableta en el momento en que el contrato fuese resuelto.<br />


            </Typography>

          </Grid>
          <Grid item xs={11} md={11} className={isMobile ? classes.mt2r : null}>
            <Typography classes={{ root: classes.labelHeader }} className={isMobile ? classes.fs20 : classes.fs24}>
              DISPOSICIONES GENERALES
            </Typography>
            <Typography className={classes.labelBody + ' ' + classes.fs16 + ' ' + classes.mt1r}>
              Vmove y el Pasajero convienen en que en caso de que cualquiera de las cláusulas y numerales del presente contrato fuere declarada, nula, ilegal o no exigible por un tribunal competente, dicha disposición será considerada e interpretada en forma separada de las demás disposiciones aquí contenidas y no afectará en forma alguna la validez, legalidad y exigibilidad de este contrato.<br />
              Estos términos y condiciones podrán ser modificados en cualquier momento por Vmove y serán notificados mediante por correo electrónico. El Pasajero tendrá derecho a terminar el contrato si no está de acuerdo con las modificaciones. En caso de que el Pasajero continúe utilizando Vmove después de haber recibido las modificaciones, se entenderá que el Pasajero ha aceptado las modificaciones.<br />
              En caso de que el Pasajero desee comunicarse con Vmove, ya sea para consultas, reclamos quejas y/o sugerencias, deberá enviar un correo electrónico a contacto@vmove.mx<br />

            </Typography>

          </Grid>
          <Grid item xs={11} md={11} className={isMobile ? classes.mt2r : null}>
            <Typography classes={{ root: classes.labelHeader }} className={isMobile ? classes.fs20 : classes.fs24}>
              POLÍTICAS DE DEVOLUCIÓN
            </Typography>
            <Typography classes={{ root: classes.labelHeader }} className={isMobile ? classes.fs20 : classes.fs24}>
              INFORMACIÓN SOBRE DERECHO A RETRACTO.
            </Typography>
            <Typography className={classes.labelBody + ' ' + classes.fs16 + ' ' + classes.mt1r}>
              Una vez que el pasajero contrate su servicio de transporte, no se realizará la devolución de dinero. Solo se hará efectivo el reembolso cuando un evento sea cancelado o postergado. <br />
              El ticket de transporte es de carácter personal, por lo que no se puede transferir a un tercero. <br />
              El ticket de traslado se podrá reutilizar en caso de que el evento se suspenda.<br />
              El Pasajero será responsable de completar el formulario (nombre, dirección y teléfono). Si por alguna razón completo mal los campos mencionados anteriormente, vmove no se hará responsable. <br />

            </Typography>

          </Grid>
          <Grid item xs={11} md={11} className={isMobile ? classes.mt2r : null}>

            <Typography classes={{ root: classes.labelHeader }} className={isMobile ? classes.fs20 : classes.fs24}>
              POLÍTICAS DE VIAJE
            </Typography>
            <Typography className={classes.labelBody + ' ' + classes.fs16 + ' ' + classes.mt1r}>
              Queda prohibido subir mascotas a los vehículos de Vmove. En caso de ser un animal de asistencia emocional, deberá ser consultado a través de nuestro correo electrónico contacto@vmove.mx<br />
              No se puede dejar ninguna pertenencia personal en los vehículos. Vmove no se hará responsable de ninguna perdida material del pasajero. <br />
              Vmove cuenta con dos servicios de traslado:<br />
              Traslado Compartido: Agrupamos personas que viven medianamente cerca, las incorporamos en un vehículo y las llevamos hacia un mismo punto en común.<br />
              Un ticket de traslado compartido corresponde a un asiento para una persona dentro del vehículo.<br />
              Traslado Privado: Se arrienda un vehículo por una cierta cantidad de hora.<br />
              Van privada de 1 a 4 pasajeros y máximo 2 direcciones.<br />
              Van privada de 5 a 7 pasajeros y máximo 3 direcciones.<br />
              Van privada 8 a 10 pasajeros y máximo 5 direcciones. <br />
              Ambos traslados son domicilios-Evento-Domicilio. <br />
              Todos los servicios son con conductor profesional.<br />
              Vmove se reserva el derecho de admisión al vehículo, ya sea por estado de ebriedad, conducta inapropiada, agresión, otros.<br />
              Vmove no cuenta con silla de seguridad para niños, si el pasajero desea, debe llevarla de forma personal, e informar al correo de servicio al cliente. <br />
              Vmove no cuenta con silla de ruedas, si el pasajero desea, debe llevarla de forma personal, e informar al correo de servicio al cliente. <br />
              Para eventos masivos Vmove podrá pasar a recoger en domicilio a los pasajeros, 4 o 5 horas aprox. antes al evento. En eventos medianos de 2 a 3 horas aprox. antes al evento. Esto con el fin de poder disminuir cualquier variable que se pudiera presentar en ruta de viaje.<br />
              El día del evento los pasajeros serán añadidos a un grupo de WhatsApp en donde se les indicara la hora estimada de recogida. Esta hora pueda variar 20 minutos antes o 20 minutos después.<br />
              En categoría compartida, el tiempo de espera del vehículo en el domicilio del pasajero, no excederá los 5 minutos. En servicios privados no excederá los 15 minutos de espera. Para ambos servicios de traslado, el tiempo de espera en el tramo de regreso, será de 5 minutos. El conductor podrá llamar al pasajero faltante para saber si tomara el traslado, entregar información de su ubicación y podrá acordar esperar 5 minutos más, pasado estos minutos, se seguirá la ruta previamente planificada.<br />
              En casos fortuitos o de fuerza mayor (accidentes, cortes en carreteras, disturbios, otros) la empresa no podrá garantizar el cumplimiento del horario acordado. <br />

            </Typography>

          </Grid>
        </Grid>

      </Container>
    </Box>
  )
}
