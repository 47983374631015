import React, { useContext, useRef, useEffect } from 'react'
import Context from '../../context/Context'
import styles from 'assets/jss/views/aboutUsPage.js'
import { makeStyles } from '@material-ui/core/styles'
import { useMediaQuery } from 'react-responsive'
import AboutUs from 'assets/img/vmove/about-us-page/about-us.png'
import Money from 'assets/img/vmove/icons/money.svg'
import Leaf from 'assets/img/vmove/icons/leaf.png'
import ReactPlayer from 'react-player'
// import LinkedInIcon from '@material-ui/icons/LinkedIn'
import Worker1 from 'assets/img/vmove/about-us-page/workers/v2/german_correa_h.jpg'
import Worker2 from 'assets/img/vmove/about-us-page/workers/v2/monica_gonzalez_c.jpg'
import Worker3 from 'assets/img/vmove/about-us-page/workers/v2/german_correa_p.jpg'
import Worker4 from 'assets/img/vmove/about-us-page/workers/v2/mikhael_marzuqa.jpg'
import Worker5 from 'assets/img/vmove/about-us-page/workers/v2/carlos_gonzalez_c.jpg'
import Worker6 from 'assets/img/vmove/about-us-page/workers/v2/vittorio_migliorini.jpg'
import Worker7 from 'assets/img/vmove/about-us-page/workers/v2/martin_dominguez.jpg'
import Worker8 from 'assets/img/vmove/about-us-page/workers/v2/katerina_Marzuqa.jpg'
import { Box, Container, Divider, Grid, Typography } from '@material-ui/core'

const useStyles = makeStyles(styles)

export default function AboutUsPage(props) {
  const divRef = useRef(null)
  const contextType = useContext(Context)
  const { t } = contextType
  const classes = useStyles()
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' })
  const customSpacing = isMobile ? 0 : 4

  useEffect(() => {
    const heightToScroll = divRef.current.offsetTop - 115
    window.scrollTo({
      top: heightToScroll,
      behavior: 'smooth',
    })
  }, [])

  return (
    <Box className={isMobile ? classes.bgWhite : null} classes={{ root: classes.contentWrapper }}>
      {isMobile ? (
        <Box>
          <img src={AboutUs} alt='About Us' className={classes.imageMobile} />
        </Box>
      ) : (
        <Box className={classes.boxContentWrapper}>{/* <img src={AboutUs} alt="About Us" className={classes.imgFluid} /> */}</Box>
      )}
      <Container ref={divRef} className={isMobile ? classes.containerDetailInfoMobile : classes.containerDetailInfo}>
        <Box classes={{ root: classes.boxTitle }} display='flex' width='100%' justifyContent='center'>
          <Typography align='center' classes={{ root: classes.labelTitle }} className={isMobile ? classes.fs25 : classes.fs50}>
            {t('who_we_are')}
          </Typography>
        </Box>
        <Divider classes={{ root: classes.customDivider }} />
        <Grid container spacing={customSpacing} className={isMobile ? classes.gridContainerBodyMobile : classes.gridContainerBody}>
          <Grid item xs={12} md={4}>
            <Typography classes={{ root: classes.labelSubtitle }} className={isMobile ? classes.fs25 : classes.fs32}>
              {t('we_are')} {t('vmove')}
            </Typography>
            <Typography classes={{ root: classes.labelBody }} className={(isMobile ? classes.fs16 : classes.fs20) + ' ' + classes.mt1r}>
              {t('about_us_left_1')}
            </Typography>
            <Box display='flex' alignItems='flex-start' className={classes.mt2r}>
              <img src={Leaf} alt='Leaf' className={classes.imageLeaf} />
              <Typography classes={{ root: classes.labelBody }}>{t('about_us_left_2')}</Typography>
            </Box>
            <Box display='flex' alignItems='flex-start' className={classes.mt2r}>
              <img src={Money} alt='Money' className={classes.imageMoney} />
              <Typography classes={{ root: classes.labelBody }} className={isMobile ? classes.labelBodyMobile : null}>
                Además, somos conscientes con tu bolsillo, por lo que podrás ahorrar hasta un 70% en servicios compartidos y nuestras
                tarifas son fijas y transparentes.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={8} className={isMobile ? classes.mt2r : null}>
            <Typography classes={{ root: classes.labelHeader }} className={isMobile ? classes.fs20 : classes.fs24}>
              {t('vmove_persons')}
            </Typography>
            <Typography className={classes.labelBody + ' ' + classes.fs16 + ' ' + classes.mt1r}>{t('about_us_right_1')}</Typography>
            <Box classes={{ root: classes.boxMedia }} className={classes.mt2r}>
              <ReactPlayer
                url={'https://www.youtube.com/watch?v=K1Pjlk8-5ZI'}
                className={classes.boxYoutube}
                playing
                width='100%'
                volume={0.2}
                controls
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={customSpacing} className={isMobile ? classes.gridContainerBodyMobile : classes.gridContainerBody}>
        </Grid>
        {/* <Grid container spacing={customSpacing} className={isMobile ? classes.gridContainerBodyMobile : classes.gridContainerBody}>
          <Grid item xs={12} md={4}>
            <Typography classes={{ root: classes.labelSubtitle }} className={isMobile ? classes.fs25 : classes.fs32}>
              {t('our_team')}
            </Typography>
            <Typography classes={{ root: classes.labelBody }} className={(isMobile ? classes.fs16 : classes.fs20) + ' ' + classes.mt1r}>
              Somos expertos que día a día trabajamos con el compromiso de entregar un mejor servicio para ti y todos nuestros usuarios.
              ¡Nos presentamos!
            </Typography>
          </Grid>
          <Grid item direction='row' alignItems='center' justify='center' container xs={12} md={8}>
            <Grid xs={6} md={3} style={{ paddingBottom: 15 }}>
              <Box className={classes.boxImage} style={{ backgroundImage: `url(${Worker1})` }}>
                <Box className={isMobile ? classes.boxWrapperMobile :classes.boxWrapper}>
                  <Box classes={{ root: classes.boxLabelInfo }}>
                    <Typography className={classes.labelBold}>Germán Correa H</Typography>
                    <Typography className={classes.labelRegular}>Director Comercial</Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid xs={6} md={3} style={{ paddingBottom: 15 }}>
              <Box className={classes.boxImage} style={{ backgroundImage: `url(${Worker2})` }}>
                <Box className={isMobile ? classes.boxWrapperMobile :classes.boxWrapper}>
                  <Box classes={{ root: classes.boxLabelInfo }}>
                    <Typography className={classes.labelBold}>Mónica González C</Typography>
                    <Typography className={classes.labelRegular}>Directora de Finanza</Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid xs={6} md={3} style={{ paddingBottom: 15 }}>
              <Box className={classes.boxImage} style={{ backgroundImage: `url(${Worker6})` }}>
                <Box className={isMobile ? classes.boxWrapperMobile :classes.boxWrapper}>
                  <Box classes={{ root: classes.boxLabelInfo }}>
                    <Typography className={classes.labelBold}>Vittorio Migliorini</Typography>
                    <Typography className={classes.labelRegular}>Director Mercado España</Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid xs={6} md={3} style={{ paddingBottom: 15 }}>
              <Box className={classes.boxImage} style={{ backgroundImage: `url(${Worker7})` }}>
                <Box className={isMobile ? classes.boxWrapperMobile :classes.boxWrapper}>
                  <Box classes={{ root: classes.boxLabelInfo }}>
                    <Typography className={classes.labelBold}>Martín Domínguez</Typography>
                    <Typography className={classes.labelRegular}>Gerente de Ventas</Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid xs={6} md={3} style={{ paddingBottom: 15 }}>
              <Box className={classes.boxImage} style={{ backgroundImage: `url(${Worker3})` }}>
                <Box className={isMobile ? classes.boxWrapperMobile :classes.boxWrapper}>
                  <Box classes={{ root: classes.boxLabelInfo }}>
                    <Typography className={classes.labelBold}>German Correa P</Typography>
                    <Typography className={classes.labelRegular}>Director Asesor Experto</Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid xs={6} md={3} style={{ paddingBottom: 15 }}>
              <Box className={classes.boxImage} style={{ backgroundImage: `url(${Worker4})` }}>
                <Box className={isMobile ? classes.boxWrapperMobile :classes.boxWrapper}>
                  <Box classes={{ root: classes.boxLabelInfo }}>
                    <Typography className={classes.labelBold}>Mikhael Marzuqa</Typography>
                    <Typography className={classes.labelRegular}>Director Asesor de Negocios</Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid xs={6} md={3} style={{ paddingBottom: 15 }}>
              <Box className={classes.boxImage} style={{ backgroundImage: `url(${Worker5})` }}>
                <Box className={isMobile ? classes.boxWrapperMobile :classes.boxWrapper}>
                  <Box classes={{ root: classes.boxLabelInfo }}>
                    <Typography className={classes.labelBold}>Carlos González C</Typography>
                    <Typography className={classes.labelRegular}>Director Asesor Experto</Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid xs={6} md={3} style={{ paddingBottom: 15 }}>
              <Box className={classes.boxImage} style={{ backgroundImage: `url(${Worker8})` }}>
                <Box className={isMobile ? classes.boxWrapperMobile :classes.boxWrapper}>
                  <Box classes={{ root: classes.boxLabelInfo }}>
                    <Typography className={classes.labelBold}>Katherine Marzuqa</Typography>
                    <Typography className={classes.labelRegular}>Directora de Experiencias</Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid> */}
      </Container>
    </Box>
  )
}
