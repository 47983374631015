import React, { Fragment, useContext, useState, useEffect } from 'react'
import Context from '../../../context/Context'
import Carousel from 'react-slick'
import { makeStyles } from '@material-ui/core/styles'
import { useMediaQuery } from 'react-responsive'
import { Avatar, Box, Container, Grid, Typography } from '@material-ui/core'
import Card from 'components/Card/Card.js'
import Facebook from '../../../assets/img/vmove/social-networks/facebook.svg'
import Instagram from '../../../assets/img/vmove/social-networks/instagram.svg'
import Youtube from '../../../assets/img/vmove/social-networks/youtube.svg'
import SocialNetwork from '../../../components/Header/InfoBar/SocialNetwork'
import styles from 'assets/jss/views/landingPageSections/comunityStyle.js'
import CustomCircularProgress from '../../../components/CircularProgress/CustomCircularProgress'

import { getComments } from 'provider/publicProvider/provider'

const useStyles = makeStyles(styles)

export default function ComunitySection() {
  const [comments, setComments] = useState([])
  const contextType = useContext(Context)
  const { getLanguage, getCountry } = contextType
  const classes = useStyles()
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' })

  useEffect(() => {
    const getLandingComments = async () => {
      getComments(getCountry()).then((res) => {
        setComments(res)
      })
    }
    getLandingComments()
  }, [getCountry(), getLanguage()])

  const settings = {
    arrows: false,
    autoplay: true,
    dots: false,
    infinite: true,
    slidesToScroll: 1,
    slidesToShow: 1,
    speed: 500,
  }

  const textTruncate = (text) => {
    return text.length > 130 ? text.substring(0, 127) + '...' : text
  }

  const shuffleArray = (array) => {
    let i = array.length - 1
    for (; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      const temp = array[i]
      array[i] = array[j]
      array[j] = temp
    }
    return array
  }

  return (
    <Box classes={{ root: classes.boxComunity }} display='flex' alignItems='center' justifyContent='center'>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} className={classes.itemComunity}>

          </Grid>
          <Grid item xs={12} md={5} className={classes.itemComunity + ' ' + classes.itemBody}>
            <Card carousel className={classes.customCard}>
              {comments.length > 0 ? (
                <Carousel {...settings}>
                  {shuffleArray(comments).map((comment, index) => {
                    return (
                      <Box key={index} className={classes.cover}>
                        <Typography classes={{ root: classes.labelComunityHeader }} className={isMobile ? classes.fs16 : classes.fs18}>
                          {textTruncate(comment.message)}
                        </Typography>
                        <Box display='flex' justifyContent='flex-start' alignItems='center' classes={{ root: classes.boxAvatar }}>
                          <Avatar alt={comment.user} src={comment.imagePath} />
                          <Box display='flex' flexDirection='column' classes={{ root: classes.boxLabelAvatar }}>
                            <Typography classes={{ root: classes.labelComunityName }} className={isMobile ? classes.fs14 : classes.fs16}>
                              {comment.user}
                            </Typography>
                            <Typography classes={{ root: classes.labelHashtag }}>
                              {comment.tags !== undefined ? (
                                <Fragment>
                                  {comment.tags.map((tag, index) => {
                                    return (
                                      <span key={index} className={classes.spanHashtag}>
                                        #{tag}
                                      </span>
                                    )
                                  })}
                                </Fragment>
                              ) : null}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    )
                  })}
                </Carousel>
              ) : (
                <CustomCircularProgress />
              )}
            </Card>
          </Grid>
          <Grid item xs={12} md={3} className={classes.itemComunity}>
            <Grid container justify='flex-end' spacing={2}>
              <Grid item className={classes.itemFullCenter}>
                <SocialNetwork image={Facebook} link={process.env.REACT_APP_LINK_FACEBOOK} text='Facebook' />
              </Grid>
              <Grid item className={classes.itemFullCenter}>
                <SocialNetwork image={Instagram} link={process.env.REACT_APP_LINK_INSTAGRAM} text='Instagram' />
              </Grid>
              <Grid item className={classes.itemFullCenter}>
                <SocialNetwork image={Youtube} link={process.env.REACT_APP_LINK_YOUTUBE} text='Youtube' />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}
