import React, { useContext } from 'react'
import styles from 'assets/jss/views/orderDetailPage.js'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography } from '@material-ui/core'
import Context from 'context/Context'
import { useMediaQuery } from 'react-responsive'

const useStyles = makeStyles(styles)

const AwaitingOrderState = () => {
    const classes = useStyles()
    const isMobile = useMediaQuery({ query: '(max-width: 959px)' })

    const contextType = useContext(Context)
    const { t } = contextType

    return (
        <Grid container spacing={2} className={classes.gridContainer}>

            <Grid item xs={12} sm={12} className={classes.mb25}>

                <Typography align={'center'} className={isMobile ? classes.subTitleMobile : classes.subTitle}>
                    {'Pendiente Pago'}
                </Typography>

            </Grid>
        </Grid>
    )
}

export default AwaitingOrderState
