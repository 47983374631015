import React, { useEffect, useState } from 'react';
import { Backdrop, Box, Button, Fade, FormControl, Grid, Input, InputAdornment, Modal, Snackbar, Typography } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles'
import styles from 'assets/jss/components/loginSelectorStyle.js'

const useStyles = makeStyles(styles)

const Unlimit = (props) => {
    const classes = useStyles()
    const { paymentResponse } = props
    const { preference_id, preference_init_point } = paymentResponse
    const [modalPay, setModalPay] = useState(false)
    const history = useHistory();
    //const handleOrder = () => history.push("/order-detail/"+preference_id);
    useEffect(() => {
        if (preference_init_point !== '') {
            //document.getElementById('formPayment').click()
            //window.open(preference_init_point, '_blank');
            setModalPay(true)
        }
    }, [preference_init_point]);

    const goOrder = () => {

        console.log('order');
        history.push("/order-detail/" + preference_id);
    };


    return (
        <div>
            {/* <iframe src={preference_init_point} height="700" width="100%" allowfullscreen="true">
                <p>Il tuo browser non supporta gli iframe.</p>
            </iframe> */}
            {/* <a target="_blank" href={preference_init_point} id='formPayment'></a> */}

            <Modal
                className={"modal modalpay" + classes.modal}
                open={modalPay}

                onClose={(event, reason) => {
                    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                        // Set 'open' to false, however you would do that with your particular code.
                        //setModalPay(false);
                        goOrder();
                    }
                }
                }
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                keepMounted
            >
                <Grid container display='flex' justify='center' alignItems='center' style={{ width: '90%', margin: 'auto', marginTop: 10 }}>
                    <div><a target='_blank' href={preference_init_point}>Ver en nueva pestaña</a></div>
                    <iframe src={preference_init_point} height="800" width="100%" allowfullscreen="true">
                        <p>Il tuo browser non supporta gli iframe.</p>
                    </iframe>
                    <div style={{ width: '100%', background: '#FFF', padding: 5 }}>
                        <Grid container spacing={1}>
                            {/* <Grid item xs={6} md={6}>
                                <Button
                                    onClick={() => goOrder()}
                                    color="danger"
                                    simple
                                    style={{ width: '100%', background: '#FFF', padding: '10px 20px' }}
                                >
                                    Cancelar y Cerrar
                                </Button>
                            </Grid> */}
                            <Grid item xs={12} md={12}>
                                <Button
                                    onClick={() => goOrder()}
                                    color="danger"
                                    simple
                                    style={{ width: '100%', background: '#FFF', padding: '10px 20px' }}
                                >
                                    Cerrar
                                </Button>
                            </Grid>
                        </Grid>
                    </div>

                </Grid>

            </Modal>
        </div >



    )
}

export default Unlimit
