import { orangeColor, whiteColor, customDivider } from "assets/jss/style.js";
import {
  mt1r,
  fs13,
  fs14,
  fs16,
  fs18,
  fs20,
  latoRegular,
  latoBold,
  w100,
  p0,
} from "assets/jss/tools.js";
import { dullOrangeColor } from "assets/jss/style";

const categoryTabStyle = {
  accorditionSumary: {
    padding: 0,
    display: "inline",
    margin: "0 !important",
  },
  popoverDetails: {
    paddingLeft: '0 10px',
    "& .MuiTypography-root": {
      margin: "0 10px",
      paddingLeft: '10px',
    },
  },
  accorditionSumaryRoot: {
    padding: 0,
    minHeight: "0 !important",
  },
  boxCategoryOption: {
    border: "1px solid #CDCDCD",
    height: "auto",
    marginBottom: "1rem",
    opacity: "1",
    borderRadius: "12px !important",
  },
  boxCategoryOptionOpen: {
    border: "1px solid",
    borderColor: orangeColor,
    height: "auto",
    marginBottom: "1rem",
    opacity: "1",
    borderRadius: "12px !important",
  },
  boxCategoryOptionBorder: {
    borderRadius: "12px !important",
  },
  boxCategoryEmpty: {
    height: "300px",
  },
  containerCategories: {
    minHeight: "calc(100% - 21px)",
  },
  containerCategoriesMobile: {
    minHeight: "calc(100% - 21px)",
    padding: "1rem 0",
  },
  categoryRecommended: {
    background: orangeColor,
    color: whiteColor,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingLeft: "1rem",
    fontFamily: latoRegular,
    borderRadius: "12px 12px 0 0",
    width: "100%",
  },
  spanRecommendText: {
    marginLeft: "4px",
  },
  itemFullCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  imageCategories: {
    height: "auto",
    width: "59px",
  },
  categoryTitle: {
    color: dullOrangeColor,
    fontFamily: latoBold,
  },
  categoryHeader: {
    color: "#7E7E7E",
    fontFamily: latoRegular,
    width: "90%",
  },
  categoryCollapseHeader: {
    color: "#7E7E7E",
    fontFamily: latoRegular,
    width: "100%",
  },
  categoryPrice: {
    color: "#7E7E7E",
    fontFamily: latoBold,
  },
  itemDivider: {
    minHeight: "calc(100% - 21px)",
  },
  itemDividerLarge: {
    minHeight: "calc(100% - 21px)",
  },
  itemDividerHorizontal: {
    width: "100%",
  },
  itemDividerLargeHorizontal: {
    minHeight: "calc(100% - 21px)",
  },
  boxDividerHorizontal: {
    height: 1,
    border: "1px dashed #CDCDCD",
    width: "100%",
  },
  boxDivider: {
    height: "100px",
    border: "1px dashed #CDCDCD",
    width: "1px",
  },
  boxCategoryOptionCollapse: {
    borderRadius: "0 0 12px 12px",
    boxShadow: "0px 3px 6px #00000029",
    height: "auto",
    opacity: "1",
  },
  expand: {
    transform: "rotate(0deg)",
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  customDividerCollapse: {
    background: "#CDCDCD",
    margin: "0 1rem",
  },
  itemSpacing: {
    padding: "0.5rem 0",
  },
  customItem: {
    display: "flex",
    paddingLeft: "0",
    paddingRight: "0",
    alignItems: "center",
  },
  customItemMobile: {
    display: "flex",
    paddingLeft: "10px",
    paddingRight: "10px",
    alignItems: "center",
  },
  customItemText: {
    color: "gray",
    margin: "0",
  },
  customItemIcon: {
    alignItems: "center",
    display: "flex",
    margin: "0",
  },
  customIcon: {
    color: orangeColor,
  },
  boxTotalButton: {
    padding: "3px 3px",
  },
  customInputTotal: {
    color: "#7E7E7E",
    fontFamily: latoBold,
  },
  boxCounterInput: {
    padding: "10px 0",
  },
  customIconQuestion: {
    color: orangeColor,
    "&:hover": {
      cursor: "pointer !important",
    },
  },
  customPaperPopover: {
    background: "#7E7E7E",
    borderRadius: "4px",
    boxShadow: "0px 3px 6px #00000029",
    height: "auto",
    maxWidth: "282px",
  },
  closePopover: {
    position: "absolute",
    // paddingBottom: '98%',
    // paddingLeft: '98%',
    marginTop: "1%",
    marginLeft: "93%",
    color: "#C4C4C4",
    fontFamily: latoBold,
    zIndex: 0,
    float: "left",
    "&:hover": {
      cursor: "pointer",
    },
  },
  customLabelPopover: {
    margin: '0 10px',
    color: whiteColor,
    fontFamily: latoRegular,
  },
  boxImagePopover: {
    // height: '117px',
    // margin: '0.5rem 0',
    // width: '100%',
    height: "100%",
    width: "100%",
    zIndex: 1,
  },
  imagePopover: {
    height: "100%",
    width: "100%",
  },
  smallLabel: {
    fontSize: "12px",
  },
  mt1r,
  customDivider,
  fs13,
  fs14,
  fs16,
  fs18,
  fs20,
  w100,
  p0,
};

export default categoryTabStyle;
