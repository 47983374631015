import React, { useContext, useRef, useEffect } from 'react'
import Context from '../../context/Context'
import styles from 'assets/jss/views/aboutUsPage.js'
import { makeStyles } from '@material-ui/core/styles'
import { useMediaQuery } from 'react-responsive'
import AboutUs from 'assets/img/vmove/about-us-page/about-us.png'
import Money from 'assets/img/vmove/icons/money.svg'
import Leaf from 'assets/img/vmove/icons/leaf.png'
import ReactPlayer from 'react-player'
// import LinkedInIcon from '@material-ui/icons/LinkedIn'
import Worker1 from 'assets/img/vmove/about-us-page/workers/v2/german_correa_h.jpg'
import Worker2 from 'assets/img/vmove/about-us-page/workers/v2/monica_gonzalez_c.jpg'
import Worker3 from 'assets/img/vmove/about-us-page/workers/v2/german_correa_p.jpg'
import Worker4 from 'assets/img/vmove/about-us-page/workers/v2/mikhael_marzuqa.jpg'
import Worker5 from 'assets/img/vmove/about-us-page/workers/v2/carlos_gonzalez_c.jpg'
import Worker6 from 'assets/img/vmove/about-us-page/workers/v2/vittorio_migliorini.jpg'
import Worker7 from 'assets/img/vmove/about-us-page/workers/v2/martin_dominguez.jpg'
import Worker8 from 'assets/img/vmove/about-us-page/workers/v2/katerina_Marzuqa.jpg'
import { Box, Container, Divider, Grid, Typography } from '@material-ui/core'

const useStyles = makeStyles(styles)

export default function PrivacidadPage(props) {
    const divRef = useRef(null)
    const contextType = useContext(Context)
    const { t } = contextType
    const classes = useStyles()
    const isMobile = useMediaQuery({ query: '(max-width: 480px)' })
    const customSpacing = isMobile ? 0 : 4

    useEffect(() => {
        const heightToScroll = divRef.current.offsetTop - 115
        window.scrollTo({
            top: heightToScroll,
            behavior: 'smooth',
        })
    }, [])

    return (
        <Box className={isMobile ? classes.bgWhite : null} classes={{ root: classes.contentWrapper }}>

            <Container ref={divRef} className={isMobile ? classes.containerDetailInfoMobile : classes.containerDetailInfo} style={{ paddingTop: 190 }}>


                <Grid container spacing={customSpacing} className={isMobile ? classes.gridContainerBodyMobile : classes.gridContainerBody}>

                    <Grid item xs={11} md={11} className={isMobile ? classes.mt2r : null}>
                        <Typography classes={{ root: classes.labelHeader }} className={isMobile ? classes.fs20 : classes.fs24}>
                            1. POLÍTICA DE PRIVACIDAD
                        </Typography>
                        <Typography className={classes.labelBody + ' ' + classes.fs16 + ' ' + classes.mt1r}>
                            VMOVE LATAM S.A. de C.V. informa a los usuarios de su página web www.vmove.mx a través de esta Política de Privacidad sobre el tratamiento y protección de los datos de carácter personal de los usuarios que puedan ser recabados mediante su navegación o contratación de servicios que realicen en este portal. El uso de esta web por parte del usuario conlleva la aceptación por su parte de esta Política de Privacidad.<br /><br />
                            VMOVE LATAM S.A. de C.V. adopta las medidas necesarias para garantizar la seguridad, integridad y confidencialidad de los datos conforme a lo establecido en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares 2011, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de los mismos.<br /><br />
                            Mediante los formularios de registro del presente sitio web o el envío de correo electrónico se recogen y tratan datos personales de cada usuario que son necesarios para la gestión y mantenimiento de algunos de los servicios prestados y cuyo tratamiento se rige por la presente Política de Privacidad.
                            <br />
                        </Typography>

                    </Grid>
                    <Grid item xs={11} md={11} className={isMobile ? classes.mt2r : null}>
                        <Typography classes={{ root: classes.labelHeader }} className={isMobile ? classes.fs20 : classes.fs24}>
                            RESPONSABLE DEL TRATAMIENTO DE LOS DATOS PERSONALES
                        </Typography>
                        <Typography className={classes.labelBody + ' ' + classes.fs16 + ' ' + classes.mt1r}>
                            El responsable del tratamiento de los datos personales que el usuario facilite al acceder y utilizar el sitio web, es, en calidad de propietario, VMOVE LATAM S.A. de C.V., con RFC VLA221021GK3, con domicilio en Paseo de la Reforma 296, Juarez, Cuauhtemoc, 06600, CDMX, teléfono 5561057480 y correo electrónico contacto@vmove.mx.<br /><br />
                            VMOVE LATAM S.A. de C.V. se compromete a aplicar las medidas de seguridad informática previstas en la normativa citada previamente, con el objetivo de impedir el acceso o uso indebido de los datos, su manipulación, deterioro o pérdida.

                            <br />
                        </Typography>

                    </Grid>
                    <Grid item xs={11} md={11} className={isMobile ? classes.mt2r : null}>
                        <Typography classes={{ root: classes.labelHeader }} className={isMobile ? classes.fs20 : classes.fs24}>
                            DURACIÓN DEL ALMACENAMIENTO DE LOS DATOS
                        </Typography>
                        <Typography className={classes.labelBody + ' ' + classes.fs16 + ' ' + classes.mt1r}>
                            VMOVE LATAM S.A. de C.V., como responsable del tratamiento, se compromete a almacenar y gestionar los datos personales e información recogida mediante esta página web con la debida confidencialidad mientras se mantenga la relación profesional surgida y la persona interesada no solicite su supresión, y en su caso, por un plazo no superior a 5 años, a contar desde la finalización de la relación comercial establecida entre el usuario y VMOVE LATAM S.A. de C.V. No obstante, el responsable del tratamiento podrá conservar, debidamente bloqueados, los datos para atender posibles responsabilidades administrativas o jurisdiccionales.
                        </Typography>

                    </Grid>

                    <Grid item xs={11} md={11} className={isMobile ? classes.mt2r : null}>
                        <Typography classes={{ root: classes.labelHeader }} className={isMobile ? classes.fs20 : classes.fs24}>
                            FINALIDADES DEL TRATAMIENTO DE DATOS
                        </Typography>
                        <Typography className={classes.labelBody + ' ' + classes.fs16 + ' ' + classes.mt1r}>
                            Las operaciones, gestiones y procedimientos técnicos, ya sean realizados de forma automatizada como no automatizada, que posibiliten la recogida, almacenamiento, modificación, transferencia y otras acciones sobre datos de carácter personal, tienen la consideración de tratamiento de datos personales. <br /> <br />
                            En VMOVE LATAM S.A. de C.V. tratamos los datos personales que el usuario nos facilita a través de www.vmove.mx con el fin de incluirlos en la agenda de contactos, gestionar la relación comercial solicitada y utilizar así esta información como medio de contacto de dicha empresa. Además, el tratamiento de estos datos recabados también implica gestionar el envío de la información que nos sea solicitada y poder responder a las consultas o cuestiones que el usuario remita a través del sitio web, facilitando a su vez a los interesados las ofertas de servicios que sean de su interés. <br /> <br />
                            Los campos de los registros se deben cumplimentar obligatoriamente para que VMOVE LATAM S.A. de C.V. pueda cumplir con las finalidades mencionadas con anterioridad. <br /> <br />
                            El usuario puede oponerse al envío de comunicaciones comerciales en cualquier momento mediante el envío de un mensaje de correo electrónico a la dirección indicada supra líneas. <br />

                        </Typography>

                    </Grid>
                    <Grid item xs={11} md={11} className={isMobile ? classes.mt2r : null}>
                        <Typography classes={{ root: classes.labelHeader }} className={isMobile ? classes.fs20 : classes.fs24}>
                            LEGITIMACIÓN PARA EL TRATAMIENTO DE LOS DATOS
                        </Typography>
                        <Typography className={classes.labelBody + ' ' + classes.fs16 + ' ' + classes.mt1r}>
                            El tratamiento de los datos personales que proporciona el usuario se realiza en base a las siguientes bases jurídicas que legitiman el mismo:<br /><br />
                            La contratación de servicios de VMOVE LATAM S.A. de C.V. y la ejecución del encargo profesional solicitado, cuyos términos y condiciones se pondrán a disposición del usuario de forma previa a una eventual contratación. Para poder llevar a cabo esta relación profesional solicitada, el interesado está obligado a facilitar sus datos.<br /><br />
                            El consentimiento libre, específico, informado e inequívoco, ya que tras la lectura de la presente Política de Privacidad el usuario queda informado y, en caso de estar conforme, puede aceptarla mediante una declaración o una clara acción afirmativa, como el marcado de una casilla que se dispone al efecto.<br /><br />
                            En el caso de que el interesado no facilite los citados datos o éstos sean erróneos o inexactos, no podremos atender su solicitud, resultando imposible proporcionarle la información solicitada o llevar a cabo la contratación de los servicios. Por tanto, el responsable del tratamiento, VMOVE LATAM S.A. de C.V., quedará exonerado de toda responsabilidad que pudiera derivarse de la no ejecución del encargo profesional o de las consecuencias que se deriven de estas informaciones inexactas o erróneas.<br /><br />

                        </Typography>

                    </Grid>

                    <Grid item xs={11} md={11} className={isMobile ? classes.mt2r : null}>
                        <Typography classes={{ root: classes.labelHeader }} className={isMobile ? classes.fs20 : classes.fs24}>
                            COMUNICACIÓN DE DATOS
                        </Typography>
                        <Typography className={classes.labelBody + ' ' + classes.fs16 + ' ' + classes.mt1r}>
                            Con carácter general VMOVE LATAM S.A. de C.V. no comunicará estos datos personales a terceros, con la salvedad de que la prestación de un servicio implique la necesidad de una relación contractual con un encargado de tratamiento y ello sea estrictamente necesario para gestionar y mantener la relación entre el usuario y la mencionada empresa, previa autorización expresa por parte del usuario. Esto se hará sólo durante el tiempo imprescindible para posibilitar la ejecución del contrato de encargo, y en las mismas condiciones y con las mismas responsabilidades que se le exigen al responsable. Una vez finalizado el encargo, el encargado del tratamiento devolverá al responsable los datos personales y suprimirá cualquier copia de la que disponga.<br /><br />
                            Por otra parte, sólo tendrán derecho a acceder a estos datos personales los terceros con los que VMOVE LATAM S.A. de C.V. tenga una obligación legal o contractual de facilitarlos, entre los que se incluyen, por ejemplo,  Jueces y Tribunales interesados en los procedimientos relacionados con las reclamaciones presentadas.<br />

                        </Typography>

                    </Grid>
                    <Grid item xs={11} md={11} className={isMobile ? classes.mt2r : null}>
                        <Typography classes={{ root: classes.labelHeader }} className={isMobile ? classes.fs20 : classes.fs24}>
                            DERECHOS DE LOS INTERESADOS
                        </Typography>
                        <Typography className={classes.labelBody + ' ' + classes.fs16 + ' ' + classes.mt1r}>
                            El usuario podrá ejercitar en todo momento, en los términos establecidos en la legislación vigente, los derechos de acceso, rectificación o supresión de datos, solicitar que se limite el tratamiento, oponerse al mismo, solicitar la portabilidad de sus datos, así como revocar el consentimiento prestado, derechos reconocidos en el citado Reglamento. El ejercicio de estos derechos puede realizarlo el propio usuario dirigiéndose a VMOVE LATAM S.A. de C.V. en la dirección de su sede social indicada anteriormente; a través del envío de un correo electrónico a la siguiente dirección contacto@vmove.mx<br /><br />
                            Para el efectivo ejercicio de estos derechos, el usuario deberá acreditar su identidad facilitando su nombre y apellidos, fotocopia del INE o documento identificativo equivalente que acredite su identidad, petición en la que se concrete la solicitud, dirección a efectos de notificaciones, y fecha y firma del solicitante.<br /><br />
                            Asimismo, el usuario podrá reclamar ante el Instituto Nacional de Transparencia, Acceso a la Información y Protección de Datos Personales (Autoridad de Control competente en esta materia), especialmente cuando no haya obtenido satisfacción en el ejercicio de sus derechos, mediante escrito dirigido a misma.<br /><br />

                        </Typography>

                    </Grid>
                    <Grid item xs={11} md={11} className={isMobile ? classes.mt2r : null}>
                        <Typography classes={{ root: classes.labelHeader }} className={isMobile ? classes.fs20 : classes.fs24}>
                            2. POLÍTICA DE COOKIES PARA PAGINA WEB
                        </Typography>
                        <Typography className={classes.labelBody + ' ' + classes.fs16 + ' ' + classes.mt1r}>
                            VMOVE LATAM S.A. de C.V. utiliza cookies propias y de terceros para obtener datos estadísticos de la navegación que realizan los usuarios y así poder recopilar información que permita optimizar su visita a las páginas web. Las cookies no serán utilizadas para recabar datos de carácter personal. Si acepta o continúa navegando, se entiende que acepta su uso. Puede optar por rechazar las cookies cuando lo desee, ya sea cambiando la configuración del explorador (si su explorador lo permite), dejando de usar este sitio web o usando las funciones de cancelación correspondientes.

                        </Typography>

                    </Grid>
                    <Grid item xs={11} md={11} className={isMobile ? classes.mt2r : null}>
                        <Typography classes={{ root: classes.labelHeader }} className={isMobile ? classes.fs20 : classes.fs24}>
                            POLÍTICA DE COOKIES:
                        </Typography>
                        <Typography className={classes.labelBody + ' ' + classes.fs16 + ' ' + classes.mt1r}>
                            Nuestro sitio web www.vmove.mx utiliza una tecnología denominada “cookies” con la finalidad de poder recabar información acerca del uso que realiza el usuario de la página web con el objetivo de analizar sus hábitos de navegación para así facilitarle la misma y poder distinguirlo de otros usuarios.<br /><br />
                            En cumplimiento con lo previsto en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares la presente Política de Cookies tiene como finalidad informar al usuario sobre la política de recogida y tratamiento de cookies.
                            <br />

                        </Typography>

                    </Grid>
                    <Grid item xs={11} md={11} className={isMobile ? classes.mt2r : null}>
                        <Typography classes={{ root: classes.labelHeader }} className={isMobile ? classes.fs20 : classes.fs24}>
                            ¿Qué son las cookies?
                        </Typography>
                        <Typography className={classes.labelBody + ' ' + classes.fs16 + ' ' + classes.mt1r}>
                            Las cookies son pequeños archivos que contienen una serie de caracteres (texto) que se envían a su explorador desde el servidor de un sitio web. La cookie puede tener un identificador único pero no contiene información de identificación personal, como su nombre o dirección de correo electrónico. VMOVE LATAM S.A. de C.V.. puede usar cookies cuando usted visita su sitio web o visita otros sitios web donde VMOVE LATAM S.A. de C.V. publicita. El explorador de Internet del usuario de esta página web almacena la cookie en el disco duro de su equipo informático y el sitio web puede acceder a ella durante su próxima visita. Otros sitios web también pueden enviar cookies a su explorador pero éste no permitirá que esos sitios web vean la información de la cookie de www.vmove.mx


                        </Typography>

                    </Grid>

                    <Grid item xs={11} md={11} className={isMobile ? classes.mt2r : null}>
                        <Typography classes={{ root: classes.labelHeader }} className={isMobile ? classes.fs20 : classes.fs24}>
                            Cookies utilizadas en este sitio web
                        </Typography>
                        <Typography className={classes.labelBody + ' ' + classes.fs16 + ' ' + classes.mt1r}>
                            Por una parte, este sitio web utiliza cookies propias técnicas para las cuales no se precisa la obtención del consentimiento del usuario, ya que están excluidas del ámbito de aplicación de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares.<br /><br />
                            Sin embargo, este sitio web también tiene instaladas cookies que requieren el consentimiento de los usuarios. A continuación, siguiendo las directrices del Instituto Nacional de Transparencia, Acceso a la Información y Protección de Datos Personales, procedemos a detallar el uso de cookies mediante su identificación y explicando su tipología y función, con el fin de informarle con la máxima exactitud posible.
                            <br />

                        </Typography>

                    </Grid>
                    <Grid item xs={11} md={11} className={isMobile ? classes.mt2r : null}>
                        <Typography classes={{ root: classes.labelHeader }} className={isMobile ? classes.fs20 : classes.fs24}>
                            Desactivación o eliminación de cookies
                        </Typography>
                        <Typography className={classes.labelBody + ' ' + classes.fs16 + ' ' + classes.mt1r}>
                            No recomendados que restrinja o bloquee cookies, ya que esto puede afectar al funcionamiento y calidad de nuestros servicios y sitios web y, por tanto, su experiencia puede resultar menos satisfactoria. Sin embargo, en cualquier momento, el usuario podrá ejercer su derecho de desactivación o eliminación de cookies de este sitio web, bloqueando o deshabilitando las mismas al modificar la configuración de las opciones del navegador instalado en su ordenador, el cual le permitirá rechazar la instalación de todas o algunas de las cookies.<br /><br />
                            Los navegadores web son las herramientas encargadas de almacenar las cookies y desde este lugar es donde el usuario debe ejercitar su derecho a eliminar o desactivar las mismas. Por tanto, www.vmove.mx no puede garantizar la correcta manipulación de las cookies por parte de los navegadores a los que hemos hecho mención.<br />


                        </Typography>

                    </Grid>
                    <Grid item xs={11} md={11} className={isMobile ? classes.mt2r : null}>
                        <Typography classes={{ root: classes.labelHeader }} className={isMobile ? classes.fs20 : classes.fs24}>
                            Cambios en la política de cookies
                        </Typography>
                        <Typography className={classes.labelBody + ' ' + classes.fs16 + ' ' + classes.mt1r}>
                            Es posible que la presente Política de Cookies sufra modificaciones en un futuro, por ello es aconsejable que cada vez que el usuario acceda a esta página web, consulte nuevamente esta política con el objetivo de estar informado adecuadamente sobre las actualizaciones que se han producido.

                        </Typography>

                    </Grid>
                    <Grid item xs={11} md={11} className={isMobile ? classes.mt2r : null}>
                        <Typography classes={{ root: classes.labelHeader }} className={isMobile ? classes.fs20 : classes.fs24}>
                            Contacto
                        </Typography>
                        <Typography className={classes.labelBody + ' ' + classes.fs16 + ' ' + classes.mt1r}>
                            Si tiene cualquier duda, comentario o sugerencia sobre nuestra Política de Cookies, no dude en ponerse en contacto con nosotros a través de la siguiente dirección de correo electrónico



                        </Typography>

                    </Grid>
                    <Grid item xs={11} md={11} className={isMobile ? classes.mt2r : null}>
                        <Typography classes={{ root: classes.labelHeader }} className={isMobile ? classes.fs20 : classes.fs24}>
                            3. AVISO LEGAL
                        </Typography>

                        <Typography className={classes.labelBody + ' ' + classes.fs16 + ' ' + classes.mt1r}>
                            VMOVE LATAM S.A. de C.V., responsable de este sitio web, pone a disposición de los usuarios de Internet el presente documento, cuya finalidad es cumplir las obligaciones establecidas en el Codigo de Comercio y demás normativa aplicable, así como proporcionar información sobre las condiciones de uso.
                        </Typography>

                    </Grid>
                    <Grid item xs={11} md={11} className={isMobile ? classes.mt2r : null}>

                        <Typography classes={{ root: classes.labelHeader }} className={isMobile ? classes.fs20 : classes.fs24}>
                            DATOS IDENTIFICATIVOS
                        </Typography>
                        <Typography className={classes.labelBody + ' ' + classes.fs16 + ' ' + classes.mt1r}>
                            En cumplimiento del deber de información regulado, a continuación se facilitan los datos necesarios del titular de la página web:
                            La empresa que gestiona los servicios y el dominio web es VMOVE LATAM S.A. de C.V., con RFC VLA221021GK3, con domicilio en Paseo de la Reforma 296, Juarez, Cuauhtemoc, 06600, CDMX.

                        </Typography>

                    </Grid>
                    <Grid item xs={11} md={11} className={isMobile ? classes.mt2r : null}>

                        <Typography classes={{ root: classes.labelHeader }} className={isMobile ? classes.fs20 : classes.fs24}>
                            USUARIOS
                        </Typography>
                        <Typography className={classes.labelBody + ' ' + classes.fs16 + ' ' + classes.mt1r}>
                            Toda persona, ya sea física o jurídica, que acceda, navegue, utilice o participe en los servicios y actividades, gratuitas u onerosas, desarrolladas mediante esta página web asume la condición de USUARIO, y como tal, a través de dicho acceso, se compromete a la observancia y riguroso cumplimiento de las disposiciones aquí presentes, así como a cualquier otra disposición legal que resulte de aplicación, obligándose así a hacer un uso correcto del sitio web. El usuario responderá frente a VMOVE LATAM S.A. de C.V. o frente a terceros, de cualesquiera daños y perjuicios que pudieran causarse como consecuencia del incumplimiento de dicha obligación.
                        </Typography>

                    </Grid>
                    <Grid item xs={11} md={11} className={isMobile ? classes.mt2r : null}>

                        <Typography classes={{ root: classes.labelHeader }} className={isMobile ? classes.fs20 : classes.fs24}>
                            MODIFICACIONES
                        </Typography>
                        <Typography className={classes.labelBody + ' ' + classes.fs16 + ' ' + classes.mt1r}>
                            VMOVE LATAM S.A. de C.V. se reserva el derecho de realizar unilateralmente las modificaciones que estime pertinentes en su portal, pudiendo cambiar, suprimir o añadir tanto los contenidos como los servicios que se presten a través del mismo, sin que exista obligación de preavisar o poner en conocimiento de los usuarios dichas modificaciones, entendiéndose como suficiente con la publicación en el sitio web del prestador.<br /><br />
                            La duración de la prestación del servicio del portal se limita al momento en que el usuario esté conectado al sitio web o a alguno de los servicios que éste proporciona. Por ello, el usuario, en cada ocasión que pretenda utilizar el portal, debe leer detenidamente este Aviso Legal, ya que el mismo y sus respectivas condiciones de uso pueden verse alteradas en cualquier momento. Por tanto, la vigencia de las citadas condiciones variará en función de su exposición y se mantendrá mientras estén debidamente publicadas, hasta que se vean sustituidas por otras.
                        </Typography>

                    </Grid>
                    <Grid item xs={11} md={11} className={isMobile ? classes.mt2r : null}>

                        <Typography classes={{ root: classes.labelHeader }} className={isMobile ? classes.fs20 : classes.fs24}>
                            CONDICIONES DE USO DEL SITIO WEB
                        </Typography>
                        <Typography className={classes.labelBody + ' ' + classes.fs16 + ' ' + classes.mt1r}>
                            Las presentes condiciones de uso y navegación tienen como finalidad regular la relación entre el titular de la página web, VMOVE LATAM S.A. de C.V., como prestador del servicio, y los usuarios que acceden, navegan y disfrutan del servicio ofrecido.<br /><br />
                            WWW.VMOVE.MX proporciona el acceso libre y gratuito a gran cantidad de información, servicios y datos (en adelante, “los contenidos”), cuya propiedad pertenece a VMOVE LATAM S.A. de C.V. o a sus licenciantes a los que el usuario puede tener acceso.<br /><br />
                            El usuario asume la responsabilidad del uso adecuado del portal de conformidad con la Ley y las presentes condiciones, responsabilidad que se extiende al registro necesario para acceder a determinados servicios y contenidos que se prestan. Dicho registro conlleva la cumplimentación del correspondiente formulario, en el cual el usuario garantiza la autenticidad y actualidad de todos aquellos datos que comunique y se compromete a no proporcionar información o documentación falsa, fraudulenta o ilícita. A raíz del mismo puede surgir la creación de una contraseña que el usuario deberá conservar diligentemente y de manera confidencial. El usuario será el único responsable de las manifestaciones falsas o inexactas que realice y de los perjuicios que cause a VMOVE LATAM S.A. de C.V. o a terceros por la información que facilite.<br /><br />
                            El usuario se compromete expresamente a hacer un adecuado uso de los contenidos y servicios que www.vmove.mx ofrece, conforme a lo establecido en la ley, la moral, el orden público y las presentes condiciones y, con carácter enunciativo, pero no limitativo, a no emplearlos para:<br /><br />
                            Difundir contenidos delictivos, violentos, pornográficos, racistas, xenófobos, ofensivos, de apología al terrorismo, que atenten contra los derechos humanos o, en general, contrarios a la ley o al orden público.<br />
                            Introducir intencionadamente en la red virus informáticos o realizar actuaciones susceptibles de alterar, estropear, interrumpir o generar errores o daños en los sistemas físicos y lógicos de VMOVE LATAM S.A. de C.V., creadora del sitio web, o de terceras personas; así como obstaculizar el acceso de otros usuarios al sitio web y a sus servicios mediante el consumo masivo de los recursos informáticos a través de los cuales VMOVE LATAM S.A. de C.V. presta sus servicios.<br />
                            Intentar acceder a las cuentas de correo electrónico de otros usuarios o a áreas restringidas de los sistemas informáticos de VMOVE LATAM S.A. de C.V. o de terceros y, en su caso, extraer información.<br />
                            Vulnerar los derechos de propiedad intelectual o industrial, así como difundir, transmitir o poner a disposición de terceros cualquier tipo de información, elemento o contenido que suponga una violación del secreto de las comunicaciones y la legislación sobre protección de datos de carácter personal.<br />
                            Suplantar la identidad de otro usuario, de las administraciones públicas o de un tercero, utilizando sus claves de registro a los distintos servicios y/o contenidos del sitio web.<br />
                            Reproducir, copiar, distribuir, poner a disposición o de cualquier otra forma comunicar públicamente, transformar o modificar los contenidos, a menos que se cuente con la autorización del titular de los correspondientes derechos de explotación o ello resulte legalmente permitido.<br />
                            Recabar datos con finalidad publicitaria y remitir publicidad de cualquier clase y comunicaciones con fines de venta u otra de naturaleza comercial sin que medie previa solicitud o consentimiento.<br /><br />

                            Cualquier incumplimiento de las cláusulas contenidas en el presente sitio web (Aviso Legal, Política de Privacidad, Política de Cookies, así como otros contenidos que supongan obligaciones para el usuario) y en general de la legalidad vigente en México, se comunicará inmediatamente por parte de VMOVE LATAM S.A. de C.V. a las autoridades pertinentes, comprometiéndose ésta a cooperar con las mismas. En tal caso, el usuario responderá frente a VMOVE LATAM S.A. de C.V. o frente a terceros, de cualesquiera daños y perjuicios que pudieran causarse como consecuencia del incumplimiento de estas obligaciones.<br /><br />
                            VMOVE LATAM S.A. de C.V. no garantiza que su página web cumpla, total o parcialmente, con las legislaciones de otros países. Por tanto, si el usuario reside o se encuentra domiciliado en cualquier otro lugar que no sea México, y decide acceder y/o navegar en el presente sitio web, lo hará bajo su propia responsabilidad y riesgo, debiendo asegurarse de que tal acceso y/o navegación cumple con la legislación local aplicable en su caso.<br /><br />
                            VMOVE LATAM S.A. de C.V. no se hace responsable de ningún daño o pérdida que se derive de un ataque de denegación de servicio, virus o cualquier otro programa o material tecnológicamente perjudicial que pueda afectar a su ordenador, equipo informático, datos o materiales como consecuencia del uso de esta página web o de la descarga de contenidos de la misma o a los que la misma redireccione.<br />


                        </Typography>

                    </Grid>
                    <Grid item xs={11} md={11} className={isMobile ? classes.mt2r : null}>

                        <Typography classes={{ root: classes.labelHeader }} className={isMobile ? classes.fs20 : classes.fs24}>
                            NOTIFICACIONES
                        </Typography>
                        <Typography className={classes.labelBody + ' ' + classes.fs16 + ' ' + classes.mt1r}>
                            Todas las notificaciones y comunicaciones entre los usuarios y VMOVE LATAM S.A. de C.V. se considerarán eficaces, a todos los efectos, cuando se realicen a través de correo postal, correo electrónico o comunicación telefónica. Los usuarios deberán dirigirse a VMOVE LATAM S.A. de C.V. mediante:<br /><br />
                            Envío por correo electrónico a la siguiente dirección: contacto@vmove.mx<br /><br />
                            Mediante el uso de esta página web, el usuario acepta implícitamente que la mayor parte de las comunicaciones se realizarán por vía electrónica. A efectos contractuales, el usuario consiente usar este medio electrónico de comunicación y reconoce que toda comunicación que se le envíe de forma electrónica cumple con los requisitos legales.<br />


                        </Typography>

                    </Grid>

                    <Grid item xs={11} md={11} className={isMobile ? classes.mt2r : null}>

                        <Typography classes={{ root: classes.labelHeader }} className={isMobile ? classes.fs20 : classes.fs24}>
                            PROTECCIÓN DE DATOS DE CARÁCTER PERSONAL
                        </Typography>
                        <Typography className={classes.labelBody + ' ' + classes.fs16 + ' ' + classes.mt1r}>
                            El titular de la página web se compromete a tratar los datos personales del usuario de conformidad con lo establecido en la legislación vigente en la materia. <br /><br />
                            Para más información sobre el tratamiento de sus datos personales en el portal, diríjase a la Política de Privacidad.


                        </Typography>

                    </Grid>

                    <Grid item xs={11} md={11} className={isMobile ? classes.mt2r : null}>

                        <Typography classes={{ root: classes.labelHeader }} className={isMobile ? classes.fs20 : classes.fs24}>
                            PROPIEDAD INTELECTUAL E INDUSTRIAL
                        </Typography>
                        <Typography className={classes.labelBody + ' ' + classes.fs16 + ' ' + classes.mt1r}>
                            Todos los contenidos del sitio web, entre otros, textos, fotografías, gráficos, imágenes, iconos, tecnología, software, links y demás contenidos audiovisuales, así como su diseño gráfico y códigos fuente, constituyen una obra cuya propiedad pertenece a VMOVE LATAM S.A. de C.V., sin que puedan entenderse cedidos al usuario ninguno de los derechos de explotación sobre los mismos más allá de lo estrictamente necesario para el correcto uso de la web, y con independencia de que sean susceptibles o no de propiedad intelectual.<br /><br />
                            Asimismo, todas las marcas, nombres comerciales o signos distintivos de cualquier clase que aparecen en el sitio web son propiedad de VMOVE LATAM S.A. de C.V., sin que pueda entenderse que el uso o acceso al mismo atribuya al usuario derecho alguno sobre los mismos.<br /><br />
                            Queda prohibida la reproducción total o parcial, explotación, distribución, modificación, cesión o comunicación pública de los contenidos y cualquier otro acto que no haya sido expresamente autorizado por el titular de los derechos de explotación. Cualquier uso no autorizado previamente se considera un incumplimiento grave de los derechos de propiedad intelectual o industrial del autor.<br /><br />
                            Para realizar cualquier tipo de observación respecto a posibles incumplimientos de los derechos de propiedad intelectual o industrial, así como sobre cualquiera de los contenidos del sitio web, el usuario deberá notificar dicha circunstancia a VMOVE LATAM S.A. de C.V. adjuntando la información pertinente.<br /><br />
                            En cualquier caso, VMOVE LATAM S.A. de C.V. no asume responsabilidad alguna respecto de los derechos de propiedad intelectual o industrial titularidad de terceros que se vean infringidos por un tercero o por el usuario.<br /><br />

                        </Typography>

                    </Grid>
                    <Grid item xs={11} md={11} className={isMobile ? classes.mt2r : null}>

                        <Typography classes={{ root: classes.labelHeader }} className={isMobile ? classes.fs20 : classes.fs24}>
                            ENLACES
                        </Typography>
                        <Typography className={classes.labelBody + ' ' + classes.fs16 + ' ' + classes.mt1r}>
                            El sitio web de VMOVE LATAM S.A. de C.V. puede contener enlaces o hipervínculos a otros sitios de Internet, respecto a los cuales no ejerce ningún tipo de control. Por tanto, una vez que el usuario acceda a los enlaces de terceros y abandone el sitio web, tanto el presente Aviso Legal, como la Política de Privacidad y la Política de Cookies dejarán de surtir efecto, ya que los sitios web a los que el usuario acceda están sujetos a sus propias políticas.<br /><br />
                            VMOVE LATAM S.A. de C.V. no puede asumir responsabilidad alguna por el contenido que pueda aparecer en páginas de terceros, ni garantiza la disponibilidad técnica, calidad, fiabilidad, exactitud, amplitud, veracidad, validez y constitucionalidad de cualquier material o información contenida en ninguno de dichos enlaces o hipervínculos. Asimismo, la inclusión de estas conexiones externas a sitios web ajenos no implica ningún tipo de asociación, fusión o participación con las entidades conectadas.

                        </Typography>

                    </Grid>
                    <Grid item xs={11} md={11} className={isMobile ? classes.mt2r : null}>

                        <Typography classes={{ root: classes.labelHeader }} className={isMobile ? classes.fs20 : classes.fs24}>
                            EXCLUSIÓN DE GARANTÍAS Y RESPONSABILIDAD
                        </Typography>
                        <Typography className={classes.labelBody + ' ' + classes.fs16 + ' ' + classes.mt1r}>
                            La información suministrada por del presente sitio web es de carácter general y tiene una finalidad meramente informativa, sin que se garantice plenamente el acceso a todos los contenidos, ni su exactitud, exhaustividad, corrección, vigencia o actualidad, ni su idoneidad o utilidad para un objetivo específico.<br /><br />
                            VMOVE LATAM S.A. de C.V. no se hace responsable, en ningún caso, de los daños y perjuicios de cualquier naturaleza derivados de, a título enunciativo:<br /><br />

                            - Lista sin orden Errores u omisiones en los contenidos. VMOVE LATAM S.A. de C.V. no garantiza que los contenidos vayan a estar actualizados permanentemente, ni que carezcan de cualquier tipo de error.<br /><br />
                            - Lista sin orden Ausencia de disponibilidad del portal. VMOVE LATAM S.A. de C.V. no se hace responsable de los posibles daños o perjuicios generados en el usuario como consecuencia de fallos o desconexiones en las redes de telecomunicaciones que supongan la suspensión, cancelación o interrupción del servicio del sitio web, ya que el funcionamiento de estas redes depende de terceros.<br /><br />
                            - Lista sin orden Presencia de virus o programas maliciosos o lesivos en los contenidos que puedan alterar los sistemas informáticos, documentos electrónicos o datos de los usuarios, a pesar de haber adoptado todas las medidas tecnológicas necesarias para prevenirlo. Corresponde al usuario, en todo caso, estar provisto de herramientas adecuadas que le protejan ante programas informáticos dañinos.<br /><br />


                        </Typography>

                    </Grid>

                    <Grid item xs={11} md={11} className={isMobile ? classes.mt2r : null}>

                        <Typography classes={{ root: classes.labelHeader }} className={isMobile ? classes.fs20 : classes.fs24}>
                            LEY APLICABLE Y JURISDICCIÓN
                        </Typography>
                        <Typography className={classes.labelBody + ' ' + classes.fs16 + ' ' + classes.mt1r}>
                            Las relaciones establecidas entre VMOVE LATAM S.A. de C.V., titular de la página web, y el usuario se regirá por la normativa mexicana vigente y la resolución de cualquier posible controversia que pueda surgir quedará sometida a los Juzgados y tribunales de la Ciudad de México.
                        </Typography>

                    </Grid>
                </Grid>

            </Container>
        </Box>
    )
}
