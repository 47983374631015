import { grayColor, grayColorFooter } from "assets/jss/style.js";
import { latoRegular, latoBold } from "assets/jss/tools.js";

const footerStyle = {
  footerContent: {
    background: grayColor
  },
  gridContainer: {
    padding: "50px 0"
  },
  itemLogo: {
    paddingRight: "60px !important"
  },
  buttonLogo: {
    width: "100%"
  },
  buttonWebpay: {
    width: "220px",
    height: "auto"
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%"
  },
  imgWebpay: {
    display: "block",
    margin: "auto",
    maxHeight: "100%",
    width: "220px"
  },
  copyRight: {
    fontFamily: latoRegular,
    fontSize: "16px"
  },
  upperText: {
    textTransform: "uppercase"
  },
  title: {
    color: grayColorFooter,
    fontFamily: latoBold,
    fontSize: "25px",
    marginBottom: "1rem",
    textTransform: "capitalize"
  },
  footerWhiteFont: {
    "&,&:hover,&:focus": {
      color: "#FFFFFF"
    }
  },
  customList: {
    padding: "0"
  },
  itemSpacing: {
    padding: "0.5rem 0"
  },
  customItem: {
    padding: "0"
  },
  customItemText: {
    fontFamily: latoRegular,
    fontSize: "20px",
    margin: "0"
  },
  customLink: {
    color: grayColorFooter,
    fontFamily: latoRegular,
    fontSize: "15px",
    lineHeight: "1.5",
    "&:hover, &:focus": {
      color: grayColorFooter,
      textDecoration: "underline",
      // textUnderlinePosition: "under"
    }
  },
  customLinkActive: {
    textDecoration: "underline",
    // textUnderlinePosition: "under",
    "&:hover, &:focus": {
      textDecoration: "underline",
      // textUnderlinePosition: "under"
    }
  },
  boxCopyRight: {
    paddingBottom: "1rem"
  }
};
export default footerStyle;
